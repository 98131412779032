import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, FormControlLabel, Button, RadioGroup, Radio, Paper, Chip, Checkbox } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { dateUtcToS } from '../../../util/dateUtil';
import { convertFile, arrayBufferToBase64 } from '../../../util/stringUtil';
import CollegeShared from './CollegeShared';

const FourYearCollege = ({ form, setForm, classes, disabled }) => {
  const [uploadError, setUploadError] = useState();
  const [studentAidReport, setStudentAidReport] = useState(null);

  useEffect(() => {
    if (form.studentAidReport) {
      let tempFile = JSON.parse(JSON.stringify(form.studentAidReport));
      tempFile.browserFile = convertFile({ file: tempFile.data, file_type: tempFile.file_type, file_name: tempFile.filename });
      setStudentAidReport(tempFile);
    }
  }, [form.studentAidReport]);

  const downloadFile = async (file) => {
    let a = document.createElement('a'); //Create <a>
    a.href = `data:${file.browserFile.type};base64,${arrayBufferToBase64(await file.browserFile.arrayBuffer())}`;
    a.download = file.browserFile.name;
    a.click();
  };

  const removeFile = () => {
    setStudentAidReport(null);
    setForm({ ...form, studentAidReport: null });
  };

  const checkExtensionForDocument = (file) => {
    return /\.(doc|docx|pdf|txt)$/i.test(file[0].name);
  };

  const confirmFileUpload = async (file) => {
    try {
      if (checkExtensionForDocument(file)) {
        const data = arrayBufferToBase64(await file[0].arrayBuffer());
        const filedetails = {
          'data': data,
          'file_type': file[0].type,
          'filename': file[0].name,
          'browserFile': convertFile({ file: data, file_type: file[0].type, file_name: file[0].name }),
        };
        setStudentAidReport(filedetails);
        let tempFile = JSON.parse(JSON.stringify(filedetails));
        delete tempFile.browserFile;
        setForm({ ...form, studentAidReport: tempFile });
        setUploadError('');
      } else {
        setUploadError('Error: The file could not be uploaded. Only Word Documents, Text Files, and PDF files can be uploaded.');
      }
    } catch (error) {
      console.log('Error setting essay details:', error);
      setUploadError('Sorry, an error occurred. Please try again.');
    }
  };

  return (
    <>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>ACT Score</label>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textRowSpacer}>
          <FormControl>
            <TextField
              id="act"
              size="small"
              hiddenLabel
              placeholder="ACT Score"
              variant="outlined"
              value={form.actScore}
              onChange={(event) => setForm({ ...form, actScore: event.target.value })}
              InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
          <FormControlLabel
            style={{ marginLeft: '10px' }}
            control={
              <Checkbox
                color="default"
                disabled={disabled}
                checked={form.actScoreNotTaken ? form.actScoreNotTaken : false}
                onChange={(ev) => {
                  if (ev.target.checked) {
                    setForm({ ...form, actScoreNotTaken: true, actScore: '' });
                  } else {
                    setForm({ ...form, actScoreNotTaken: ev.target.checked });
                  }
                }}
              />
            }
            label="Have Not Taken"
          />
        </Grid>
        <Grid item xs={false} md={3} lg={false} />
        {form.actScoreNotTaken && (
          <>
            <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
              <label>Explain why ACT not taken</label>
            </Grid>
            <Grid item xs={12} md={6} className={classes.textRowSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="act-not-taken-reason"
                  size="small"
                  hiddenLabel
                  placeholder="Please provide more information"
                  variant="outlined"
                  value={form.actScoreNotTakenReason}
                  onChange={(event) => setForm({ ...form, actScoreNotTakenReason: event.target.value })}
                  InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={false} md={3} lg={false} />
          </>
        )}
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>ACT Score Date</label>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rowSpacer}>
          {!disabled ? (
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                id="act-date"
                disableFuture
                hiddenLabel
                value={form.actDate || null}
                onChange={(date) => {
                  setForm({ ...form, actDate: date ? date : null });
                }}
                format="MM/dd/yyyy"
                className={classes.datepicker}
                disabled={disabled}
              />
            </MuiPickersUtilsProvider>
          ) : (
            <summary style={{ marginTop: 3 }} className={classes.subtleBold}>
              {dateUtcToS(form.actDate || null)}
            </summary>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={6} md={2} className={classes.textRowLabelSpacer}>
          <label>ACT Score</label>
        </Grid>
        {!form.actScoreNotTaken && (
          <Grid item xs={6} md={4} className={classes.textRowSpacer}>
            {form.actScore}
          </Grid>
        )}
        {form.actScoreNotTaken && (
          <Grid item xs={6} md={4} className={classes.textRowSpacer}>
            <span>ACT Not taken: </span>
            {form.actScoreNotTakenReason}
          </Grid>
        )}
        <Grid item xs={6} md={2} className={classes.textRowLabelSpacer}>
          <label>Date</label>
        </Grid>
        <Grid item xs={6} md={4} className={classes.rowSpacer}>
          {dateUtcToS(form.actDate || null)}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>Student Aid Report?</label>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="Student Aid Report?"
              name="student-aid-report"
              defaultValue={form.studentAidReportCompleted}
              value={form.studentAidReportCompleted}
              onChange={(event) => setForm({ ...form, studentAidReportCompleted: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={6} sm={2} className={classes.textRowLabelSpacer}>
          <label>Student Aid Report?</label>
        </Grid>
        <Grid item xs={6} sm={4} className={classes.rowSpacer}>
          {form.studentAidReportCompleted}
        </Grid>
      </Grid>

      {form.studentAidReportCompleted === 'yes' && (
        <Grid container direction="row">
          <Grid item xs={12} className={classes.unprintedContent}>
            <Paper style={{ marginBottom: '20px' }}>
              <div style={{ borderTop: '3px dotted teal', padding: 20 }}>
                {studentAidReport && (
                  <div style={{ marginBottom: 10 }}>
                    <Chip
                      style={{ margin: 5 }}
                      label={studentAidReport && studentAidReport.filename}
                      onDelete={disabled ? null : removeFile}
                      color="primary"
                      onClick={() => downloadFile(studentAidReport)}
                    />
                  </div>
                )}
                {!studentAidReport && (
                  <div>
                    <Button variant="outlined" component="label" color="primary">
                      Upload Student Aid Report
                      <input type="file" hidden onChange={(e) => confirmFileUpload(e.target.files)} />
                    </Button>
                    {studentAidReport && studentAidReport.name}
                    <br />
                    <aside className={classes.error}>{uploadError}</aside>
                  </div>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
      {form.studentAidReportCompleted === 'yes' && studentAidReport && (
        <Grid container direction="row">
          <Grid item xs={12} className={classes.printedContent}>
            {studentAidReport.filename} has been uploaded.
          </Grid>
        </Grid>
      )}

      <CollegeShared form={form} setForm={setForm} classes={classes} disabled={disabled} />
    </>
  );
};

FourYearCollege.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default FourYearCollege;
