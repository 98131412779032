import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, Select, MenuItem, RadioGroup, Radio, FormControlLabel, Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const CollegeShared = ({ form, setForm, classes, disabled }) => {
  const [majorLabel, setMajorLabel] = useState('Major');

  useEffect(() => {
    if (form.postGradPlan === '4-year College' && majorLabel !== 'Major') {
      setMajorLabel('Major');
    }
    if (form.postGradPlan === 'Trade/2-year College' && majorLabel !== 'Trade or Major') {
      setMajorLabel('Trade or Major');
    }
  }, [form.postGradPlan]);

  return (
    <>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>FAFSA Completed?</label>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="FAFSA completed?"
              name="fafsa-completed"
              defaultValue={form.fafsaCompleted}
              value={form.fafsaCompleted}
              onChange={(event) => setForm({ ...form, fafsaCompleted: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
              <FormControlLabel
                value="scheduled"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Scheduled</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>FAFSA Completed?</label>
        </Grid>
        <Grid item xs={false} md={3} lg={false} />
        <Grid item xs={6} md={6} className={classes.rowSpacer}>
          {form.fafsaCompleted}
        </Grid>
      </Grid>

      {form.fafsaCompleted === 'yes' && (
        <>
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
              <label>Pell Elligible?</label>
              <Tooltip title="This information is available on the FASFA report which is emailed.">
                <IconButton aria-label="This information is available on the FASFA report which is emailed.">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} className={classes.rowSpacer}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="Pell Eligible?"
                  name="Pell Eligible"
                  value={form.pellElligible}
                  onChange={(event) => setForm({ ...form, pellElligible: event.target.value })}
                  disabled={disabled}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>Yes</span>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="default" disabled={disabled} />}
                    label={<span className={classes.radioLabel}>No</span>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
              <label>Pell Elligible?</label>
            </Grid>
            <Grid item xs={false} md={3} lg={false} />
            <Grid item xs={6} md={6} className={classes.rowSpacer}>
              {form.pellElligible}
            </Grid>
          </Grid>
        </>
      )}

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>School Name</label>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="school-name"
              size="small"
              hiddenLabel
              placeholder="School Name"
              variant="outlined"
              value={form.schoolName}
              onChange={(event) => setForm({ ...form, schoolName: event.target.value })}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              disabled={disabled}
              className={classes.root}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>School Name</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={5} className={classes.rowSpacer}>
          {form.schoolName}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>{majorLabel}</label>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="major-field-of-work-study"
              size="small"
              hiddenLabel
              placeholder={majorLabel}
              variant="outlined"
              value={form.majorFieldOfWorkOrStudy}
              onChange={(event) => setForm({ ...form, majorFieldOfWorkOrStudy: event.target.value })}
              InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
              disabled={disabled}
              className={classes.root}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>{majorLabel}</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={5} className={classes.rowSpacer}>
          {form.majorFieldOfWorkOrStudy}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>Housing Plan</label>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <Select
              label="Select Housing Plan"
              value={form.housingPlan ?? ''}
              defaultValue=""
              onChange={(e) => {
                if (e.target.value === 'Off-Campus') {
                  setForm({ ...form, housingPlan: e.target.value, offCampusHousingPlanExplain: '' });
                } else {
                  setForm({ ...form, housingPlan: e.target.value });
                }
              }}
              InputProps={{
                'aria-label': 'Housing Plan',
                name: 'two-year-housing-plan',
                id: 'two-year-housing-plan',
              }}
              disabled={disabled}
            >
              <MenuItem aria-label="None" value=""></MenuItem>
              <MenuItem value="On-Campus">On-Campus</MenuItem>
              <MenuItem value="Off-Campus">Off-Campus</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>Housing Plan</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={4} className={classes.rowSpacer}>
          {form.housingPlan ?? ''}
        </Grid>
      </Grid>

      {form.housingPlan === 'Off-Campus' && (
        <>
          <Grid container direction="row" className={classes.unprintedContent}>
            <Grid item xs={12} className={classes.rowSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="off-campus-housing-explain"
                  size="small"
                  label="Explain your off-campus housing plan."
                  variant="outlined"
                  value={form.offCampusHousingPlanExplain}
                  onChange={(event) => setForm({ ...form, offCampusHousingPlanExplain: event.target.value })}
                  InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.printedContent}>
            <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
              <label>Explain</label>
            </Grid>
            <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
            <Grid item xs={11} className={classes.rowSpacer}>
              {form.offCampusHousingPlanExplain}
            </Grid>
          </Grid>
        </>
      )}

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item sm={6} md={3} className={classes.textRowLabelSpacer}>
          <label>Do you plan to have a part time job while you are in school?</label>
        </Grid>
        <Grid item sm={6} md={6} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="Hold a part time job?"
              name="hold-a-part-time-job"
              defaultValue={form.holdPartTimeJob}
              value={form.holdPartTimeJob}
              onChange={(event) => setForm({ ...form, holdPartTimeJob: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Do you plan to have a part time job while you are in school?</label>
        </Grid>
        <Grid item xs={12} className={classes.rowSpacer}>
          {form.holdPartTimeJob}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="other-scholarships-applied"
              size="small"
              label="Please describe other scholarships for which I have applied."
              variant="outlined"
              value={form.otherScholarshipsApplied}
              onChange={(event) => setForm({ ...form, otherScholarshipsApplied: event.target.value })}
              InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Please describe other scholarships for which I have applied.</label>
        </Grid>
        <Grid item xs={12} className={classes.rowSpacer}>
          {form.otherScholarshipsApplied}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} className={classes.textRowSpacer}>
          <FormControl fullWidth>
            <TextField
              id="other-scholarships-awarded"
              size="small"
              label="Please describe other scholarships I have been awarded."
              variant="outlined"
              value={form.otherScholarshipsAwarded}
              onChange={(event) => setForm({ ...form, otherScholarshipsAwarded: event.target.value })}
              InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} className={classes.textRowLabelSpacer}>
          <label>Please describe other scholarships I have been awarded.</label>
        </Grid>
        <Grid item xs={12} className={classes.rowSpacer}>
          {form.otherScholarshipsAwarded}
        </Grid>
      </Grid>
    </>
  );
};

CollegeShared.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CollegeShared;
