import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, FormControl, TextField } from '@material-ui/core';
import LuxonUtils from '@date-io/luxon';
import { displayDateAsUTC } from '../../../util/dateUtil';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const styles = makeStyles((theme) => ({
  sectionHeader: {
    fontSize: '0.90rem',
    fontWeight: 600,
    margin: '5px 0',
  },
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 70,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
  marginRight30: {
    marginRight: 30,
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  marginTop15: {
    marginTop: 15,
  },
  marginTop15Bottom10: {
    margin: '15px 0 10px 0',
  },
  instructions: {
    color: '#000',
  },
}));

export default function InformedConsent(props) {
  const { form, setForm, serviceType, joinBoth, disabled } = props;

  const classes = styles();

  return (
    <Box sx={{ width: '95%' }}>
      {(serviceType === 'tradelife' || (serviceType === 'housing' && joinBoth === 'yes')) && (
        <React.Fragment>
          <section className={classes.sectionHeader}>Successful Futures - Trade Life</section>
          <section>
            <label className={classes.instructions}>
              All information supplied in this application is true and complete to the best of my knowledge. If asked, I agree to provide
              documentation of the information contained herein. I understand that participation in the{' '}
              <em>Successful Futures - Trade Life Program</em> is voluntary. Meeting the program requirements is necessary to continue to be a part of
              the program and receive the benefits of it.
            </label>
            <summary className={classes.marginTop15Bottom10}>
              <label className={classes.instructions}>Program Requirements</label>
            </summary>
            <label className={classes.instructions}>
              <ol>
                <li>Participants must maintain a schedule that includes a total of 35 hours per week of classes and/or employment.</li>
                <li>Participants must participate in Applied Life Skills Curriculum.</li>
                <li>Participants must attend scheduled Focus Meetings.</li>
                <li>Participants must sign and adhere to the Trade Life Program Agreement.</li>
              </ol>
            </label>
            <label className={classes.instructions}>
              I understand that there are certain circumstances that will exclude me from the Trade Life program. These include, but are not limited
              to: marriage, adding dependent children, not being employed/in school, illegal activity/charged, or the need for increased services.
            </label>
          </section>

          <section className={classes.marginTop15}>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={8} sm={6} md={4} lg={3} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="trade-life-signature"
                    size="small"
                    label="Signature"
                    variant="outlined"
                    value={form.tradeLifeSignature}
                    onChange={(event) => setForm({ ...form, tradeLifeSignature: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 75, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={6} md={1} lg={1} className={classes.textRowSpacer} />
              <Grid item xs={4} sm={3} md={2} lg={2} className={classes.textRowSpacer}>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    id="trade-life-date"
                    hiddenLabel
                    disableFuture
                    value={form.tradeLifeDate || null}
                    onChange={(date) => {
                      setForm({ ...form, tradeLifeDate: date ? date : null });
                    }}
                    format="MM/dd/yyyy"
                    className={classes.datepicker}
                    disabled={disabled}
                    maxDateMessage="Date cannot be later than today."
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={8} sm={9} md={7} lg={6} className={classes.textRowSpacer} />
            </Grid>

            <Grid container direction="row" className={classes.printedContent}>
              <Grid item xs={5}>
                <summary className={classes.alignPrintedContent}>{form.tradeLifeSignature || '** missing signature **'}</summary>
              </Grid>
              <Grid item xs={3}>
                <summary className={classes.alignPrintedContent}>{form.tradeLifeDate ? displayDateAsUTC(form.tradeLifeDate) : null}</summary>
              </Grid>
            </Grid>
          </section>
        </React.Fragment>
      )}
      {serviceType === 'housing' && (
        <React.Fragment>
          <section className={classes.sectionHeader}>Successful Futures - Housing</section>
          <section>
            <label className={classes.instructions}>
              All information supplied in this application is true and complete to the best of my knowledge. If asked, I agree to provide
              documentation of the information contained herein. I understand that participation in the <em>Successful Futures - Housing Program</em>{' '}
              is voluntary. Meeting the program requirements is necessary to continue to be a part of the program and receive the benefits of it.
            </label>
            <summary className={classes.marginTop15Bottom10}>
              <label className={classes.instructions}>Program Requirements</label>
            </summary>
            <label className={classes.instructions}>
              <ol>
                <li>
                  Participants must be enrolled as fulltime students or working fulltime (35 hours minimum), or a combination of both education and
                  employment to equal fulltime status.
                </li>
                <li>Participants must participate in Applied Life Skills curriculum.</li>
                <li>Participants must attend scheduled Focus Meetings.</li>
                <li>
                  Participants must commit to maintain all medical, dental, and vision appointments and keep corresponding prescriptions current.
                </li>
                <li>Participants must sign and adhere to the Successful Futures Housing Handbook.</li>
              </ol>
            </label>
            <label className={classes.instructions}>
              I understand that there are certain circumstances that will exclude me from the Housing program. These include, but are not limited to:
              marriage, adding dependent children, not being employed/in school, illegal activity/charged, or the need for increased services.
            </label>
          </section>

          <section className={classes.marginTop15}>
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={8} sm={6} md={4} lg={3} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="housing-services-signature"
                    size="small"
                    label="Signature"
                    variant="outlined"
                    value={form.housingServicesSignature}
                    onChange={(event) => setForm({ ...form, housingServicesSignature: event.target.value })}
                    disabled={disabled}
                    InputProps={{ maxLength: 75, classes: { input: classes.textField } }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={6} md={1} lg={1} className={classes.textRowSpacer} />
              <Grid item xs={4} sm={3} md={2} lg={2} className={classes.textRowSpacer}>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    id="housing-services-date"
                    hiddenLabel
                    disableFuture
                    value={form.housingServicesDate || null}
                    onChange={(date) => {
                      setForm({ ...form, housingServicesDate: date ? date : null });
                    }}
                    format="MM/dd/yyyy"
                    className={classes.datepicker}
                    disabled={disabled}
                    maxDateMessage="Date cannot be later than today."
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={8} sm={9} md={7} lg={6} className={classes.textRowSpacer} />
            </Grid>
            <Grid container direction="row" className={classes.printedContent}>
              <Grid item xs={5}>
                <summary className={classes.alignPrintedContent}>{form.housingServicesSignature || '** missing signature **'}</summary>
              </Grid>
              <Grid item xs={3}>
                <summary className={classes.alignPrintedContent}>
                  {form.housingServicesDate ? displayDateAsUTC(form.housingServicesDate) : null}
                </summary>
              </Grid>
            </Grid>
          </section>
        </React.Fragment>
      )}
    </Box>
  );
}

InformedConsent.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  serviceType: PropTypes.string,
  joinBoth: PropTypes.string,
  disabled: PropTypes.bool,
};
