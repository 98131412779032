import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { dateUtcToS, dateToApi, dateDiffYears, getTodaysDate, dateOnlyFormat, dateUtcToyyyyMMdd } from '../../util/dateUtil';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Hidden,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  TablePagination,
} from '@material-ui/core';
import TableStyle from '../../styles/TableStyle';
import TitleBar from '../Shared/TitleBar';
import { observer, inject } from 'mobx-react';
import { logException } from '../../services/appInsights';
import { btNavyBlueColor, btMediumGrayColor } from '../../assets/jss/nextjs-material-kit-pro';
import PleaseWait from '../Shared/PleaseWait';
import AlumniListCards from './AlumniListCards';
import { callApi } from '../../util/apiWrapper';
import Paper from '@material-ui/core/Paper';

const tableStyles = makeStyles(TableStyle);

const useStyles = makeStyles((theme) => ({
  titlebar: {
    backgroundColor: '#fff',
    padding: '30px 10px 5px 10px',
    margin: '-15px 0 15px 0',
    borderBottom: '1px inset #f0f0f0',
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 600,
  },
  breadcrumb: {
    padding: '18px 0 0 10px',
  },
  breadCrumbText: {
    fontSize: '0.80rem',
  },
  noLeftPadding: {
    paddingLeft: '0 !important',
  },
  sortHeader: {
    cursor: 'pointer',
    color: btNavyBlueColor,
  },
  nosortHeader: {
    color: btMediumGrayColor,
  },
  idLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
  },
  idNoLink: {
    padding: 0,
  },
  gridLink: {
    color: btNavyBlueColor,
    fontSize: '0.75rem',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    textTransform: 'none',
    textDecoration: 'underline',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerAlignContent: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20,
    marginTop: '10px',
  },
  pointer: {
    cursor: 'pointer',
  },
  filter: {
    margin: '5px 0 15px 20px',
  },
  toolMargins: {
    margin: '5px 0 0 20px',
  },
  switchLeadingText: {
    margin: '5px 20px 15px 0',
    lineHeight: '30px',
    verticalAlign: 'middle',
  },
  switchAlignment: {
    lineHeight: '28px',
    verticalAlign: 'middle',
  },
  noSeniorsMsg: {
    fontSize: '1.15rem',
    margin: 5,
  },
  whiteBg: {
    background: '#FFFFFF',
  },
  centerRadioSorting: {
    marginLeft: 20,
    marginRight: 20,
    justifyContent: 'space-between',
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
  selectDropdown: {
    color: '#333',
    backgroundColor: '#fff',
  },
  menuItem: {
    '&:hover': {
      color: '#fff',
      backgroundColor: '#8c857b',
    },
  },
  enhancedFilterButton: {
    marginTop: 5,
    minHeight: 37,
  },
}));

const calculateAge = (dob) => {
  return dateDiffYears(dateToApi(dateUtcToyyyyMMdd(dob)), dateToApi(getTodaysDate()));
};

const AlumniListTable = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;

    const history = useHistory();

    const classes = useStyles();
    const tableClasses = tableStyles();

    const [sortBy, setSortBy] = React.useState('last_name');
    const [sortDirection, setSortDirection] = React.useState('asc');
    const [filter, setFilter] = React.useState('');
    const [allseniors, setAllSeniors] = React.useState([]);
    const [seniors, setSeniors] = React.useState([]);
    const [programs, setPrograms] = React.useState([]);

    useEffect(() => {
      async function fetchSeniors() {
        try {
          store.setSearching(true);
          const seniorData = await callApi(auth, `alumni`, 'GET');
          const allseniors = seniorData && seniorData.result;
          const siteseniors = allseniors.filter((s) => store.staffsites.includes(s.site));
          store.setSenior(null);
          store.setSeniorData(siteseniors);
          store.setAllSeniors(siteseniors);
          setAllSeniors(siteseniors);
          setSeniors(siteseniors);
          store.setSearching(false);
          store.setLoading(false);
          setSortBy('last_name');
        } catch (error) {
          logException(error);
          store.setSearching(false);
          store.setLoading(false);
          console.log('Error fetching senior list:', error);
        }
      }
      async function fetchPrograms() {
        try {
          const programData = await callApi(auth, `alumni/ndbprogram`, 'GET');
          setPrograms(programData.result);
        } catch (error) {
          logException(error);
          console.log('Error fetching ndb program list:', error);
        }
      }
      if (auth) {
        fetchPrograms();
        fetchSeniors();
      }
    }, [auth, store]);

    useEffect(() => {
      if (auth.roles && auth.roles.length > 0) {
        if (auth.roles.includes('STUDENT')) {
          history.push('/seniorplanner');
        }
      }
    }, [auth.roles, history]);

    useEffect(() => {
      const compareValues = (key, order = 'asc') => {
        return function innerSort(a, b) {
          if (key === 'user') {
            let aVal = '';
            let bVal = '';
            if (a[key]) {
              aVal = a[key] && a[key].email.length > 0 ? a[key].email : '';
            }
            if (b[key]) {
              bVal = b[key] && b[key].email.length > 0 ? b[key].email : '';
            }
            const varA = standarizeEmail(aVal);
            const varB = standarizeEmail(bVal);
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
          } else if (key === 'user.is_active') {
            let aVal = null;
            let bVal = null;
            aVal = a['user'].is_active.toString();
            bVal = b['user'].is_active.toString();
            const varA = aVal === 'true' ? 'Y' : 'N';
            const varB = bVal === 'true' ? 'Y' : 'N';
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
          } else if (key === 'departure_date') {
            let aVal = '';
            let bVal = '';
            if (a[key]) {
              aVal = a[key] && a[key].length > 0 ? dateOnlyFormat(a[key]).toString() : '0000-00-00';
            }
            if (b[key]) {
              bVal = b[key] && b[key].length > 0 ? dateOnlyFormat(b[key]).toString() : '0000-00-00';
            }
            const varA = aVal;
            const varB = bVal;
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
          } else if (key === 'dob') {
            let aVal = 0;
            let bVal = 0;
            if (a[key]) {
              aVal = a[key] && a[key].length > 0 ? dateDiffYears(dateToApi(dateUtcToyyyyMMdd(a[key])), dateToApi(getTodaysDate())) : 0;
            }
            if (b[key]) {
              bVal = b[key] && b[key].length > 0 ? dateDiffYears(dateToApi(dateUtcToyyyyMMdd(b[key])), dateToApi(getTodaysDate())) : 0;
            }
            const varA = aVal;
            const varB = bVal;
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
          } else {
            let aVal = '';
            let bVal = '';
            if (a[key]) {
              aVal = a[key] ? a[key] : '';
            }
            if (b[key]) {
              bVal = b[key] ? b[key] : '';
            }
            const varA = aVal;
            const varB = bVal;
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
          }
        };
      };

      // Filter Field
      let filteredData = allseniors;
      if (filter && filter.length > 0) {
        filteredData = filteredData.filter(
          (s) =>
            (s.int_id && s.int_id.toString().includes(filter)) ||
            (s.first_name && s.first_name.toLowerCase().includes(filter.toLowerCase())) ||
            (s.last_name && s.last_name.toLowerCase().includes(filter.toLowerCase())) ||
            (s.dob && calculateAge(s.dob).toString().toLowerCase().includes(filter)) ||
            (s.user.email && standarizeEmail(s.user.email).toString().toLowerCase().includes(filter.toLowerCase())) ||
            (s.site && s.site.toLowerCase().includes(filter.toLowerCase())) ||
            (s.program_node_name && translateProgram(s.program_node_name).toLowerCase().includes(filter.toLowerCase())) ||
            (s.departure_date && s.departure_date > /Date(0)/ && dateUtcToS(s.departure_date).includes(filter.toLowerCase()))
        );
      }

      store.setPage(0);
      store.setTotalSeniors(filteredData.length);

      // SORTING
      filteredData.sort(compareValues(sortBy, sortDirection));
      setSeniors(filteredData);
    }, [store, sortBy, filter, sortDirection, allseniors]);

    const lookupProgram = (id) => {
      if (!id || id === 0) return '';
      const idx = programs.findIndex((p) => p.int_id === id);
      if (idx > -1) {
        return translateProgram(programs[idx].program_node_name);
      }
    };

    const translateProgram = (name) => {
      switch (name) {
        case 'Aftercare Independence':
          return 'Trade Life';
        case 'Aftercare Transitional Living':
          return 'Housing';
        default:
          return '';
      }
    };

    const setSort = (val) => {
      if (val === sortBy) {
        if (sortDirection === 'asc') {
          setSortDirection('desc');
        } else {
          setSortDirection('asc');
        }
      } else {
        setSortBy(val);
        setSortDirection('asc');
      }
    };

    const standarizeEmail = (e) => {
      if (e) {
        const emailParts = e.split('@');
        const domainParts = emailParts[1].split('.');
        const stdemail = emailParts[0].toLowerCase() + '@' + domainParts[0].toLowerCase() + '.' + domainParts[1].toLowerCase();
        return stdemail;
      } else {
        return '';
      }
    };

    const filterData = (val) => {
      setFilter(val);
    };

    const handleClickId = (event, id) => {
      event.preventDefault();
      event.stopPropagation();
      store.setBasicInfoId(id);
      store.setAlumni(true);
      history.push('/seniorplanner');
    };

    const emptyRows = store.perPage - Math.min(store.perPage, seniors && seniors.length - store.page * store.perPage);

    return (
      <Grid container className={classes.whiteBg} style={{ width: '95vw' }}>
        {auth.roles && !auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI') && (
          <React.Fragment>
            <PleaseWait isLoading={store.loading} />
            <TitleBar title="Boys Town Alumni" currentPage="Boys Town Alumni List" paths={[{ path: '/', name: 'Home' }]} additionalInformation="" />
            <Grid container className={classes.whiteBg}>
              <Grid item xs={12} className={classes.whiteBg} align="left">
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={4} lg={3} className={classes.whiteBg} align="left">
                    <TextField
                      id="filter-field"
                      type="search"
                      className={classes.filter}
                      size="small"
                      hiddenLabel
                      placeholder="Filter"
                      variant="outlined"
                      onChange={(event) => filterData(event.target.value)}
                      clearable="true"
                    />
                  </Grid>
                  <Grid item xs={12} sm={7} md={4} lg={4} className={classes.whiteBg} align="left"></Grid>
                  <Grid item xs={12} sm={5} md={4} lg={5} align="right" className={classes.whiteBg}></Grid>
                </Grid>
                <Hidden mdDown implementation="css">
                  <TableContainer component={Paper}>
                    <Table className={tableClasses.table}>
                      <TableHead>
                        <TableRow>
                          <Tooltip title="Click to sort by Last Name." placement="top">
                            <TableCell onClick={() => setSort('last_name')} className={classes.sortHeader} style={{ width: '220px' }}>
                              NAME (NDB ID){' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: sortBy === 'last_name' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: sortBy === 'last_name' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by Email." placement="top">
                            <TableCell onClick={() => setSort('user')} className={classes.sortHeader} style={{ width: '180px' }}>
                              EMAIL ADDRESS{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: sortBy === 'user' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: sortBy === 'user' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <TableCell onClick={() => setSort('dob')} className={classes.sortHeader} style={{ width: '80px' }}>
                            AGE{' '}
                            <span
                              className="fas fa-caret-up"
                              style={{
                                display: sortBy === 'dob' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                              }}
                            />
                            <span
                              className="fas fa-caret-down"
                              style={{
                                display: sortBy === 'dob' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                              }}
                            />
                          </TableCell>
                          <TableCell onClick={() => setSort('departure_date')} className={classes.sortHeader} style={{ width: '110px' }}>
                            DEPARTURE DATE{' '}
                            <span
                              className="fas fa-caret-up"
                              style={{
                                display: sortBy === 'departure_date' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                              }}
                            />
                            <span
                              className="fas fa-caret-down"
                              style={{
                                display: sortBy === 'departure_date' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                              }}
                            />
                          </TableCell>
                          <TableCell onClick={() => setSort('site')} className={classes.sortHeader} style={{ width: '130px' }}>
                            SITE{' '}
                            <span
                              className="fas fa-caret-up"
                              style={{
                                display: sortBy === 'site' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                              }}
                            />
                            <span
                              className="fas fa-caret-down"
                              style={{
                                display: sortBy === 'site' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                              }}
                            />
                          </TableCell>
                          <TableCell className={classes.nosortHeader} style={{ width: '110px' }}>
                            PROGRAM
                          </TableCell>
                          <TableCell
                            onClick={() => setSort('user.is_active')}
                            className={classes.sortHeader}
                            style={{ width: '80px', textAlign: 'center' }}
                          >
                            ACTIVE{' '}
                            <span
                              className="fas fa-caret-up"
                              style={{
                                display: sortBy === 'user.is_active' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                              }}
                            />
                            <span
                              className="fas fa-caret-down"
                              style={{
                                display: sortBy === 'user.is_active' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {store.searching ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              <Typography variant="h5">Searching...</Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          seniors &&
                          seniors.slice(store.page * store.perPage, store.page * store.perPage + store.perPage).map((senior, idx) => (
                            <TableRow key={senior.id + '_' + idx} className={classes.pointer} onClick={(ev) => handleClickId(ev, senior.id)}>
                              <TableCell style={{ width: '200px' }}>
                                {senior && senior.alumni_updated_last_name && senior.alumni_updated_last_name.length > 0
                                  ? senior.alumni_updated_last_name
                                  : senior.last_name}
                                {', '}
                                {senior && senior.alumni_updated_first_name && senior.alumni_updated_first_name.length > 0
                                  ? senior.alumni_updated_first_name
                                  : senior.first_name}{' '}
                                ({senior.int_id})
                              </TableCell>
                              <TableCell style={{ width: '90px' }}>
                                {senior.user.email && <label className={classes.idNoLink}>{senior.user.email}</label>}
                              </TableCell>
                              <TableCell style={{ width: '90px' }}>{senior.dob && calculateAge(senior.dob)}</TableCell>
                              <TableCell style={{ width: '120px' }}>
                                {senior.departure_date > /Date(0)/ && dateUtcToS(senior.departure_date)}
                              </TableCell>
                              <TableCell style={{ width: '150px' }}>{senior.site}</TableCell>
                              <TableCell style={{ width: '150px' }}>{lookupProgram(senior.int_id)}</TableCell>
                              <TableCell style={{ width: '80px', textAlign: 'center' }}>{senior.user.is_active ? 'Y' : 'N'}</TableCell>
                            </TableRow>
                          ))
                        )}
                        {!store.searching && seniors && seniors.length === 0 ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8} className={classes.noLeftPadding}>
                              <summary className={classes.noSeniorsMsg}>No records found.</summary>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <React.Fragment />
                        )}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 34 * emptyRows }}>
                            <TableCell colSpan={8} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      colSpan={8}
                      SelectProps={{
                        InputProps: { 'aria-label': 'rows per page' },
                        MenuProps: { classes: { paper: classes.selectDropdown } },
                      }}
                      classes={{ menuItem: classes.menuItem }}
                      rowsPerPageOptions={[10, 15, 25, 50, 100, 150, 200, 250, 500]}
                      count={store.seniorPages}
                      rowsPerPage={store.perPage}
                      page={store.page}
                      onPageChange={(ev, val) => store.handlePageChange(ev, val)}
                      onRowsPerPageChange={(ev) => store.handleChangeRowsPerPage(ev)}
                    />
                  </TableContainer>
                </Hidden>
                <Hidden lgUp implementation="css">
                  <AlumniListCards SeniorStore={store} seniors={seniors} programs={programs} />
                </Hidden>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  })
);

export default AlumniListTable;
