import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControl, MenuItem, Select } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function Scoring(props) {
  const { form, setForm, disabled } = props;

  const classes = styles();

  return (
    <>
      <Grid item xs={12} align="center" style={{ margin: '15px 0' }}>
        <label style={{ fontWeight: 600 }}>Grading the Planning Meeting Form 2</label>
      </Grid>
      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Preparation Sheet #2</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Sheet is fully completed by student.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="sheet 2 fully completed"
            id="sheet-2-completed"
            value={form.gradingSheet2Completed}
            onChange={(event) => setForm({ ...form, gradingSheet2Completed: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={9}>Sheet was thoughtfully completed by student</MenuItem>
            <MenuItem value={5}>Sheet was completed by student but requires more work</MenuItem>
            <MenuItem value={3}>Sheet completion needs significant improvement</MenuItem>
            <MenuItem value={0}>Sheet was not completed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Sheet is submitted on time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="sheet 2 submitted on time"
            id="sheet-2-submitted"
            value={form.gradingSheet2Submitted}
            onChange={(event) => setForm({ ...form, gradingSheet2Submitted: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={8}>Sheet was submitted on time</MenuItem>
            <MenuItem value={4}>Sheet was submitted late (within 3 days after initial meeting)</MenuItem>
            <MenuItem value={2}>Sheet was submitted significantly late (between 4 and 7 days after initial meeting)</MenuItem>
            <MenuItem value={0}>Sheet was not submitted</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 10 }}>
        <label>Student is able to clearly speak about her/his plan options and rationales.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 10 }}>
        <FormControl fullWidth>
          <Select
            labelId="sheet 2 clarity"
            id="sheet-2-clarity"
            value={form.gradingSheet2Clarity}
            onChange={(event) => setForm({ ...form, gradingSheet2Clarity: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={8}>Student showed clarity and confidence about his/her post-graduation plans</MenuItem>
            <MenuItem value={3}>Student was hesitant and vacillating about his/her post-graduation plans</MenuItem>
            <MenuItem value={0}>Student could not speak about her/his post-graduation plans</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Preparation Sheet #2 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {(form.gradingSheet2Completed && form.gradingSheet2Completed >= 0 ? form.gradingSheet2Completed : 0) +
          (form.gradingSheet2Submitted && form.gradingSheet2Submitted >= 0 ? form.gradingSheet2Submitted : 0) +
          (form.gradingSheet2Clarity && form.gradingSheet2Clarity >= 0 ? form.gradingSheet2Clarity : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Module #4</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Module was submitted on time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="module 4 submitted"
            id="module-4-submitted"
            value={form.module4Submitted}
            onChange={(event) => setForm({ ...form, module4Submitted: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={10}>Module was submitted on time</MenuItem>
            <MenuItem value={6}>Module was submitted late (within 3 days after initial meeting)</MenuItem>
            <MenuItem value={3}>Module was submitted significantly late (between 4 and 7 days after initial meeting)</MenuItem>
            <MenuItem value={0}>Module was not submitted</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 35 }}>
        <label>
          Worksheets and activities (housing questions, family contract, if applicable, transportation options, affording a car, etc.) thoughtfully
          completed with realistic exploration of options and clear notes and follow-up descriptions.
        </label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 35 }}>
        <FormControl fullWidth>
          <Select
            labelId="module 4 thoughtfully completed"
            id="module-4-completed"
            value={form.module4Completed}
            onChange={(event) => setForm({ ...form, module4Completed: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={15}>Module was thoughtfully completed by student</MenuItem>
            <MenuItem value={10}>Module was completed by student but requires more work</MenuItem>
            <MenuItem value={5}>Module completion needs significant improvement</MenuItem>
            <MenuItem value={0}>Module was not completed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Module #4 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {(form.module4Submitted && form.module4Submitted >= 0 ? form.module4Submitted : 0) +
          (form.module4Completed && form.module4Completed >= 0 ? form.module4Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Module #5</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Module was submitted on time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="module 5 submitted"
            id="module-5-submitted"
            value={form.module5Submitted}
            onChange={(event) => setForm({ ...form, module5Submitted: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={10}>Module was submitted on time</MenuItem>
            <MenuItem value={6}>Module was submitted late (within 3 days after initial meeting)</MenuItem>
            <MenuItem value={3}>Module was submitted significantly late (between 4 and 7 days after initial meeting)</MenuItem>
            <MenuItem value={0}>Module was not submitted</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label>
          Health Worksheet and activities (insurance, healthy lifestyle questions, SODAs etc.) thoughtfully completed with realistic exploration of
          options and clear notes and follow-up descriptions.
        </label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <FormControl fullWidth>
          <Select
            labelId="module 5 thoughtfully completed"
            id="module-5-completed"
            value={form.module5Completed}
            onChange={(event) => setForm({ ...form, module5Completed: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={15}>Module was thoughtfully completed by student</MenuItem>
            <MenuItem value={10}>Module was completed by student but requires more work</MenuItem>
            <MenuItem value={5}>Module completion needs significant improvement</MenuItem>
            <MenuItem value={0}>Module was not completed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Module #5 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {(form.module5Submitted && form.module5Submitted >= 0 ? form.module5Submitted : 0) +
          (form.module5Completed && form.module5Completed >= 0 ? form.module5Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Module #6</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Module was submitted on time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="module 6 submitted"
            id="module-6-submitted"
            value={form.module6Submitted}
            onChange={(event) => setForm({ ...form, module6Submitted: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={10}>Module was submitted on time</MenuItem>
            <MenuItem value={6}>Module was submitted late (within 3 days after initial meeting)</MenuItem>
            <MenuItem value={3}>Module was submitted significantly late (between 4 and 7 days after initial meeting)</MenuItem>
            <MenuItem value={0}>Module was not submitted</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 40 }}>
        <label>
          Worksheets (spirituality/faith, personal boundaries, community resources, etc.) and activities (friends, relationships with adults, support
          systems, time management, etc.) thoughtfully completed with realistic exploration of options and clear notes and follow-up descriptions.
        </label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <FormControl fullWidth>
          <Select
            labelId="module 6 thoughtfully completed"
            id="module-6-completed"
            value={form.module6Completed}
            onChange={(event) => setForm({ ...form, module6Completed: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={15}>Module was thoughtfully completed by student</MenuItem>
            <MenuItem value={10}>Module was completed by student but requires more work</MenuItem>
            <MenuItem value={5}>Module completion needs significant improvement</MenuItem>
            <MenuItem value={0}>Module was not completed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Module #6 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {(form.module6Submitted && form.module6Submitted >= 0 ? form.module6Submitted : 0) +
          (form.module6Completed && form.module6Completed >= 0 ? form.module6Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label style={{ fontWeight: 600 }}>Overall Total Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        {(form.gradingSheet2Completed && form.gradingSheet2Completed >= 0 ? form.gradingSheet2Completed : 0) +
          (form.gradingSheet2Submitted && form.gradingSheet2Submitted >= 0 ? form.gradingSheet2Submitted : 0) +
          (form.gradingSheet2Clarity && form.gradingSheet2Clarity >= 0 ? form.gradingSheet2Clarity : 0) +
          (form.module4Submitted && form.module4Submitted >= 0 ? form.module4Submitted : 0) +
          (form.module4Completed && form.module4Completed >= 0 ? form.module4Completed : 0) +
          (form.module5Submitted && form.module5Submitted >= 0 ? form.module5Submitted : 0) +
          (form.module5Completed && form.module5Completed >= 0 ? form.module5Completed : 0) +
          (form.module6Submitted && form.module6Submitted >= 0 ? form.module6Submitted : 0) +
          (form.module6Completed && form.module6Completed >= 0 ? form.module6Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
    </>
  );
}

Scoring.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
};
