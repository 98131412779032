import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormLabel,
  Grid,
  Hidden,
  FormControlLabel,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  TablePagination,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import TableStyle from '../../styles/TableStyle';
import { observer, inject } from 'mobx-react';
import { logException } from '../../services/appInsights';
import { btNavyBlueColor, btMediumGrayColor } from '../../assets/jss/nextjs-material-kit-pro';
import PleaseWait from '../Shared/PleaseWait';
import { callApi, BadRequestError } from '../../util/apiWrapper';
import { logAudit } from '../../util/auditUtil';
import { dateUtcToyyyyMMdd, dateToS, dateToMMMMYYYY } from '../../util/dateUtil';
import { stateLabelValues } from '../../util/stringUtil';
import TitleBar from '../Shared/TitleBar';
import AccessRequestListCards from './accessRequestListCards';
import PageUnauthorized from '../Shared/PageUnauthorized';
import DetermineAccess from './AccessRequestDialogs/DetermineAccess';

const tableStyles = makeStyles(TableStyle);

const useStyles = makeStyles((theme) => ({
  noLeftPadding: {
    paddingLeft: '0 !important',
  },
  sortHeader: {
    cursor: 'pointer',
    color: btNavyBlueColor,
  },
  nosortHeader: {
    color: btMediumGrayColor,
  },
  idLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
    textTransform: 'none',
    justifyContent: 'left',
  },
  idNoLink: {
    padding: 0,
  },
  gridLink: {
    color: btNavyBlueColor,
    fontSize: '0.75rem',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    textTransform: 'none',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerAlignContent: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  filter: {
    margin: '5px 0 15px 20px',
  },
  switchLeadingText: {
    margin: '5px 20px 15px 0',
  },
  noSeniorsMsg: {
    fontSize: '1.15rem',
    margin: 5,
  },
  whiteBg: {
    background: '#FFFFFF',
  },
  centerRadioSorting: {
    marginLeft: 20,
    marginRight: 20,
    justifyContent: 'space-between',
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
  selectDropdown: {
    color: '#333',
    backgroundColor: '#fff',
  },
  menuItem: {
    '&:hover': {
      color: '#fff',
      backgroundColor: '#8c857b',
    },
  },
}));

const AccessRequestList = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;

    const history = useHistory();

    const classes = useStyles();
    const tableClasses = tableStyles();

    const [pageState, setPageState] = useState({
      allRecords: [],
      records: [],
      filter: '',
      fetchedRecords: false,
      loading: false,
      showAllRecords: false,
      sortDirection: 'asc',
      sortBy: 'last_name',
    });
    const [errorMsg, setErrorMsg] = useState(null);
    const [pageOptions, setPageOptions] = useState({
      searching: false,
      page: 0,
      perPage: 15,
    });
    const [request, setRequest] = useState({});
    const [showDetermineAccess, setShowDetermineAccess] = useState(false);
    const [ndbId, setNdbId] = useState('');
    const [denyExplain, setDenyExplain] = useState('');
    const [disposition, setDisposition] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const fetchRecords = async () => {
      try {
        setPageState({ ...pageState, loading: true });
        const rsp = await callApi(auth, 'accessrequest/pending', 'GET');
        for (let i = 0; i < rsp.result.length; i++) {
          const r = rsp.result[i];
          r.dob_formatted = dateToS(r.dob);
          r.admission_date_formatted = dateToMMMMYYYY(r.admission_date);
          r.departure_date_formatted = dateToMMMMYYYY(r.departure_date);
          r.created_date_formatted = dateToS(r.created_at);
          r.program_attended = r.program_attended ? r.program_attended.replace(',', ', ') : '';
        }
        setPageState({
          ...pageState,
          loading: false,
          records: [...rsp.result],
          allRecords: [...rsp.result],
          sortBy: 'last_name',
          fetchedRecords: true,
        });
      } catch (error) {
        setPageState({ ...pageState, loading: false, fetchedRecords: true });
        setErrorMsg('An unexpected error has occurred. Please try again.');
        logException(error);
        console.log('Error fetching acccess request list:', error);
      }
    };

    useEffect(() => {
      if (auth.authToken && !pageState.fetchedRecords && !pageState.loading) {
        fetchRecords();
      }
      setSort('last_name');
    }, [auth]);

    useEffect(() => {
      if (auth.roles && auth.roles.length > 0) {
        if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
          history.push('/seniorplanner');
        }
      }
    }, [auth.roles, history]);

    // {record.created_at > /Date(0)/ && record.created_date_formatted}

    useEffect(() => {
      const compareValues = (key, order = 'asc') => {
        return function innerSort(a, b) {
          const varA = !a.hasOwnProperty(key)
            ? ''
            : key === 'first_name' || key === 'last_name'
            ? a[key].toLowerCase()
            : key === 'dob' || key === 'admission_date' || key === 'departure_date' || key === 'created_at'
            ? dateToNum(dateToS(a[key]))
            : key === 'email'
            ? standarizeEmail(a[key])
            : a[key];
          const varB = !b.hasOwnProperty(key)
            ? ''
            : key === 'first_name' || key === 'last_name'
            ? b[key].toLowerCase()
            : key === 'dob' || key === 'admission_date' || key === 'departure_date' || key === 'created_at'
            ? dateToNum(dateToS(b[key]))
            : key === 'email'
            ? standarizeEmail(b[key])
            : b[key];
          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return order === 'desc' ? comparison * -1 : comparison;
        };
      };

      let sortedRecords = pageState.allRecords;
      if (sortedRecords) {
        if (!pageState.showAllRecords) {
          sortedRecords = sortedRecords.filter((x) => !x.approved);
        }
        sortedRecords.sort(compareValues(pageState.sortBy, pageState.sortDirection));
        setPageState((p) => {
          return { ...p, records: sortedRecords };
        });
      }
    }, [pageState.showAllRecords, pageState.sortBy, pageState.sortDirection, pageState.allRecords]);

    useEffect(() => {
      let filteredData = pageState.allRecords || [];
      if (!pageState.showAllRecords) {
        pageState.filteredData = filteredData.filter((x) => !x.approved);
      }
      if (pageState.filter && pageState.filter.length > 0) {
        filteredData = filteredData.filter((r) => {
          return (
            (r.first_name && r.first_name.toLowerCase().includes(pageState.filter.toLowerCase())) ||
            (r.last_name && r.last_name.toLowerCase().includes(pageState.filter.toLowerCase())) ||
            (r.dob_formatted && r.dob_formatted.toLowerCase().includes(pageState.filter)) ||
            (r.email && standarizeEmail(r.email).includes(pageState.filter.toLowerCase())) ||
            (r.program_attended && r.program_attended.toLowerCase().includes(pageState.filter.toLowerCase())) ||
            (r.admission_date_formatted && r.admission_date_formatted.toLowerCase().includes(pageState.filter)) ||
            (r.departure_date_formatted && r.departure_date_formatted.toLowerCase().includes(pageState.filter))
          );
        });
      } else {
        if (!pageState.showAllRecords) {
          filteredData = filteredData.filter((x) => !x.approved);
        }
      }
      setPageState((p) => {
        return { ...p, records: filteredData };
      });
    }, [pageState.filter, pageState.showAllRecords, pageState.allRecords]);

    const setSort = (val) => {
      let direction = 'asc';
      if (val === pageState.sortBy) {
        if (pageState.sortDirection === 'asc') {
          direction = 'desc';
        } else {
          direction = 'asc';
        }
        setPageState({ ...pageState, sortDirection: direction });
      } else {
        setPageState({ ...pageState, sortDirection: 'asc', sortBy: val });
      }
    };

    const dateToNum = (d) => {
      d = d.split('/');
      return Number(d[2] + d[0] + d[1]);
    };

    const standarizeEmail = (e) => {
      if (e) {
        const emailParts = e.split('@');
        const domainParts = emailParts[1].split('.');
        const stdemail = emailParts[0].toLowerCase() + '@' + domainParts[0].toLowerCase() + '.' + domainParts[1].toLowerCase();
        return stdemail;
      } else {
        return '';
      }
    };

    const filterData = (val) => {
      setPageState({ ...pageState, filter: val });
    };

    // const handleShowAll = (checked) => {
    //   setPageState({ ...pageState, showAllRecords: checked });
    // };

    const handleClickId = (event, row) => {
      event.preventDefault();
      event.stopPropagation();
      setRequest(row);
      setShowDetermineAccess(true);
    };

    const handleCardSortChange = (event) => {
      setPageState({ ...pageState, sortBy: event.target.value });
    };

    const handleDetermineAccess = async (updatedRequest, access, ndbId) => {
      if (access && ndbId.length < 1) {
        setErrorMsg('You must enter an NDB Id to approve an access request.');
        return;
      }

      if (access === false && denyExplain.length < 1) {
        console.log('You must enter an explanation to deny an access request.');
        setErrorMsg('You must enter an explanation to deny an access request.');
        return;
      }

      async function writeAccess() {
        updatedRequest.approved = access;
        updatedRequest.approved_by = auth.user.result.id;
        updatedRequest.approved_date = new Date();
        updatedRequest.updated_at = new Date();

        await callApi(auth, 'accessrequestapprovedeny', 'PUT', {
          id: updatedRequest.id,
          email: updatedRequest.email,
          approved: access,
          ndb_id: ndbId,
          deny_explain: denyExplain,
        });

        const auditObj = {
          audit_ts: new Date(),
          user_id: auth.user.result.id,
          username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
          audit_type: 'access request',
          action: access,
          value: `${updatedRequest.email} access request ${access ? 'approved' : 'denied'}.`,
        };
        logAudit(auth, auditObj);
        setNdbId('');
        setDenyExplain('');
        setErrorMsg('');
        setDisposition('');
        handleClose();
        fetchRecords();
      }

      if (access) {
        // Fetch record from NDB data and write to student table as an alumni
        const ndbrsp = await callApi(auth, 'accessrequest/data?ndb_id=' + ndbId, 'GET');

        if (ndbrsp.result.length > 0) {
          const ndbClient = ndbrsp.result[0];
          let homeStateName = '';
          if (ndbClient.state_code) {
            const stateArray = stateLabelValues;
            homeStateName = stateArray.find((s) => s.value === ndbClient.state_code).label;
          }

          let newAlumObj = {
            'email': updatedRequest.email,
            'int_id': ndbId,
            'first_name': ndbClient.first_name,
            'last_name': ndbClient.last_name,
            'dob': ndbClient.birthdate ? dateUtcToyyyyMMdd(ndbClient.birthdate) : null,
            'admission_date': ndbClient.admissionDate ? dateUtcToyyyyMMdd(ndbClient.admissionDate) : null,
            'departure_date': ndbClient.dischargeDate ? dateUtcToyyyyMMdd(ndbClient.dischargeDate) : null,
            'favorable_discharge': ndbClient.favorable_discharge === 'Y' ? true : ndbClient.favorable_discharge === 'N' ? false : null,
            'site': ndbClient.site_node_name ? ndbClient.site_node_name : null,
            'home_state': homeStateName,
            'graduation_date': null,
            'departure_reason': ndbClient.departureReason,
            'sex': ndbClient.sex,
            'alumni_updated_first_name': updatedRequest.first_name,
            'alumni_updated_last_name': updatedRequest.last_name,
            'updated_by': auth.user.result.id,
            'updated_at': new Date(),
            'contact_updated_by': auth.user.result.id,
            'contact_updated_at': new Date(),
          };

          try {
            setIsLoading(true);
            const newAlum = await callApi(auth, 'alumniuser', 'POST', {
              item: newAlumObj,
            });
            setIsLoading(false);
            // Set new user as an alum, set store id, go to senior planner
            store.setAlumni(true);
            store.setBasicInfoId(newAlum.result.id);
            history.push('/seniorplanner');
          } catch (error) {
            if (error instanceof BadRequestError) {
              setIsLoading(false);
              const msg = JSON.parse(error.message);
              setErrorMsg(msg.responseStatus.message);
              setNdbId('');
              setDenyExplain('');
              setDisposition('');
              return;
            } else {
              setErrorMsg('An unexpected error has occurred. Please try again at a later time.');
              setIsLoading(false);
              return;
            }
          }
        } else {
          setErrorMsg('The NDB Id is not associated with an FHP admission. Only clients with an FHP admission can be added to Successful Futures.');
          return;
        }
      }

      writeAccess();
    };

    const handleClose = () => {
      setErrorMsg('');
      setDisposition('');
      setRequest({});
      setShowDetermineAccess(false);
    };

    const emptyRows =
      pageOptions.perPage - Math.min(pageOptions.perPage, pageState.records && pageState.records.length - pageOptions.page * pageOptions.perPage);

    if (auth.roles && (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI'))) {
      return <PageUnauthorized />;
    }

    return (
      <Grid container className={classes.whiteBg}>
        <PleaseWait isLoading={isLoading} />
        <React.Fragment>
          <PleaseWait isLoading={pageState.loading} />
          <TitleBar title="Onboarding Requests" currentPage="Onboarding Requests" paths={[{ path: '/', name: 'Home' }]} />

          <Grid container className={classes.whiteBg}>
            <Grid item xs={12}>
              <FormLabel error>{errorMsg}</FormLabel>
            </Grid>

            <Grid container className={classes.whiteBg} align="left">
              <Grid item xs={6} className={classes.whiteBg} align="left">
                <TextField
                  id="filter-field"
                  type="search"
                  className={classes.filter}
                  size="small"
                  hiddenLabel
                  placeholder="Filter"
                  variant="outlined"
                  onChange={(event) => filterData(event.target.value)}
                  clearable="true"
                />
              </Grid>
              {/* <Grid item xs={6} align="right" className={classes.whiteBg}>
                <Hidden xsDown implementation="css">
                  <span className={classes.switchLeadingText}>Show Pending</span>
                  <FormControlLabel
                    control={
                      <Switch checked={store.afterHours} color="secondary" onChange={(ev) => handleShowAll(ev.target.checked)} name="showall" />
                    }
                    label="Show All"
                  />
                </Hidden>
              </Grid> */}

              <Grid item xs={12}>
                <Hidden mdDown implementation="css">
                  <TableContainer component={Paper}>
                    <Table className={tableClasses.table}>
                      <TableHead>
                        <TableRow className={classes.pointer}>
                          <Tooltip title="Click to sort by Last Name." placement="top">
                            <TableCell onClick={() => setSort('last_name')} className={classes.sortHeader} style={{ width: '120px' }}>
                              NAME{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: pageState.sortBy === 'last_name' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: pageState.sortBy === 'last_name' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by Id." placement="top">
                            <TableCell onClick={() => setSort('email')} className={classes.sortHeader} style={{ width: '110px' }}>
                              EMAIL{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: pageState.sortBy === 'email' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: pageState.sortBy === 'email' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <TableCell onClick={() => setSort('created_at')} className={classes.sortHeader} style={{ width: '110px' }} align="center">
                            ONBOARDING REQUEST DATE{' '}
                            <span
                              className="fas fa-caret-up"
                              style={{
                                display: pageState.sortBy === 'created_at' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                              }}
                            />
                            <span
                              className="fas fa-caret-down"
                              style={{
                                display: pageState.sortBy === 'created_at' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                              }}
                            />
                          </TableCell>
                          {pageState.showAllRecords && (
                            <TableCell className={classes.nosortHeader} style={{ width: '70px', textAlign: 'center' }}>
                              APPROVED
                            </TableCell>
                          )}
                          {pageState.showAllRecords && (
                            <TableCell className={classes.nosortHeader} style={{ width: '90px' }}>
                              APPROVED BY
                            </TableCell>
                          )}
                          {pageState.showAllRecords && (
                            <TableCell className={classes.nosortHeader} style={{ width: '90px' }}>
                              APPROVED DATE
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pageOptions.searching ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              <Typography variant="h5">Searching...</Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          pageState.records &&
                          pageState.records
                            .slice(pageOptions.page * pageOptions.perPage, pageOptions.page * pageOptions.perPage + pageOptions.perPage)
                            .map((record, idx) => (
                              <TableRow key={record.id} onClick={(ev) => handleClickId(ev, record)}>
                                <TableCell style={{ width: '80px' }}>
                                  <label>{`${record.first_name} ${record.last_name}`}</label>
                                </TableCell>
                                <TableCell style={{ width: '100px' }}>{record.email}</TableCell>
                                <TableCell style={{ width: '80px' }} align="center">
                                  {record.created_at > /Date(0)/ && record.created_date_formatted}
                                </TableCell>
                                {pageState.showAllRecords ? (
                                  <TableCell style={{ textAlign: 'center' }}>
                                    {record.approved && record.approved === true && <CheckIcon color="primary" />}
                                  </TableCell>
                                ) : (
                                  <React.Fragment>
                                    <TableCell style={{ width: '70px' }}></TableCell>
                                    <TableCell style={{ width: '90px' }}></TableCell>
                                    <TableCell style={{ width: '90px' }}></TableCell>
                                  </React.Fragment>
                                )}
                                {pageState.showAllRecords && <TableCell>{record.approved_by}</TableCell>}
                                {pageState.showAllRecords && (
                                  <TableCell>{record.approved_date > /Date(0)/ && dateToS(record.approved_date)}</TableCell>
                                )}
                              </TableRow>
                            ))
                        )}
                        {!pageOptions.searching && pageState.records && pageState.records.length === 0 ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8} className={classes.noLeftPadding}>
                              <summary className={classes.noSeniorsMsg}>No records found.</summary>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <React.Fragment />
                        )}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 34 * emptyRows }}>
                            <TableCell colSpan={8} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      colSpan={8}
                      SelectProps={{
                        InputProps: { 'aria-label': 'rows per page' },
                        MenuProps: { classes: { paper: classes.selectDropdown } },
                      }}
                      classes={{ menuItem: classes.menuItem }}
                      rowsPerPageOptions={[10, 15, 25, 50, 100]}
                      count={pageState.records ? pageState.records.length : 0}
                      rowsPerPage={pageOptions.perPage}
                      page={pageOptions.page}
                      onChangePage={(ev, val) => {
                        setPageOptions({ ...pageOptions, page: val });
                      }}
                      onChangeRowsPerPage={(ev) => setPageOptions({ ...pageOptions, perPage: parseInt(ev.target.value, 10), page: 0 })}
                      showFirstButton
                      showLastButton
                    />
                  </TableContainer>
                </Hidden>

                <Hidden lgUp implementation="css">
                  <Grid container>
                    <Grid item xs={12} sm={7} md={5} className={classes.radioRow}>
                      <RadioGroup
                        aria-label="card-sorting"
                        name="date-group"
                        className={classes.centerRadioSorting}
                        row
                        value={pageState.sortBy}
                        onChange={handleCardSortChange}
                      >
                        <FormControlLabel
                          value="last_name"
                          control={<Radio color="default" />}
                          label={<span className={classes.radioLabel}>Sort by Name</span>}
                          onClick={() => setSort('last_name')}
                          className={classes.radioLabel}
                        />
                        <aside
                          style={{
                            marginLeft: '-15px',
                            marginTop: '8px',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            className="fas fa-caret-up"
                            style={{
                              display: pageState.sortBy === 'last_name' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                          <span
                            className="fas fa-caret-down"
                            style={{
                              display: pageState.sortBy === 'last_name' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                        </aside>
                        <FormControlLabel
                          value="email"
                          control={<Radio color="default" />}
                          label={<span className={classes.radioLabel}>Sort by Email</span>}
                          onClick={() => setSort('email')}
                          className={classes.radioLabel}
                          style={{
                            marginLeft: '20px',
                          }}
                        />
                        <aside
                          style={{
                            marginLeft: '-15px',
                            marginTop: '8px',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            className="fas fa-caret-up"
                            style={{
                              display: pageState.sortBy === 'email' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                          <span
                            className="fas fa-caret-down"
                            style={{
                              display: pageState.sortBy === 'email' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                        </aside>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <AccessRequestListCards list={pageState.records} showAllRecords={pageState.showAllRecords} />
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
        <DetermineAccess
          SeniorStore={store}
          AuthStore={auth}
          request={request}
          ndbId={ndbId}
          setNdbId={setNdbId}
          denyExplain={denyExplain}
          setDenyExplain={setDenyExplain}
          handleDetermineAccess={handleDetermineAccess}
          handleClose={handleClose}
          showDetermineAccess={showDetermineAccess}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          disposition={disposition}
          setDisposition={setDisposition}
        />
      </Grid>
    );
  })
);

export default AccessRequestList;
