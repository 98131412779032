import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControl, MenuItem, Select } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
}));

export default function Scoring(props) {
  const { form, setForm, disabled } = props;

  const classes = styles();

  return (
    <>
      <Grid item xs={12} align="center" style={{ margin: '15px 0' }}>
        <label style={{ fontWeight: 600 }}>Grading the Planning Meeting Form 1</label>
      </Grid>
      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Preparation Sheet #1</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Sheet is fully completed by student.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="sheet 1 fully completed"
            id="sheet-1-completed"
            value={form.gradingSheet1Completed}
            onChange={(event) => setForm({ ...form, gradingSheet1Completed: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={9}>Sheet was thoughtfully completed by student</MenuItem>
            <MenuItem value={6}>Sheet was completed by student but requires more work</MenuItem>
            <MenuItem value={3}>Sheet completion needs significant improvement</MenuItem>
            <MenuItem value={0}>Sheet was not completed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Sheet is submitted on time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="sheet 1 submitted on time"
            id="sheet-1-submitted"
            value={form.gradingSheet1Submitted}
            onChange={(event) => setForm({ ...form, gradingSheet1Submitted: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={8}>Sheet was submitted on time</MenuItem>
            <MenuItem value={6}>Sheet was submitted late (within 3 days after initial meeting)</MenuItem>
            <MenuItem value={3}>Sheet was submitted significantly late (between 4 and 7 days after initial meeting)</MenuItem>
            <MenuItem value={0}>Sheet was not submitted</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 10 }}>
        <label>Student is able to clearly speak about her/his plan options and rationales.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 10 }}>
        <FormControl fullWidth>
          <Select
            labelId="sheet 1 clarity"
            id="sheet-1-clarity"
            value={form.gradingSheet1Clarity}
            onChange={(event) => setForm({ ...form, gradingSheet1Clarity: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={8}>Student showed clarity and confidence about his/her post-graduation plans</MenuItem>
            <MenuItem value={6}>Student was hesitant and vacillating about his/her post-graduation plan</MenuItem>
            <MenuItem value={0}>Student could not speak about her/his post-graduation plan</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Preparation Sheet #1 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {(form.gradingSheet1Completed && form.gradingSheet1Completed >= 0 ? form.gradingSheet1Completed : 0) +
          (form.gradingSheet1Submitted && form.gradingSheet1Submitted >= 0 ? form.gradingSheet1Submitted : 0) +
          (form.gradingSheet1Clarity && form.gradingSheet1Clarity >= 0 ? form.gradingSheet1Clarity : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Module #1</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Module was submitted on time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="module 1 submitted"
            id="module-1-submitted"
            value={form.module1Submitted}
            onChange={(event) => setForm({ ...form, module1Submitted: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={10}>Module was submitted on time</MenuItem>
            <MenuItem value={6}>Module was submitted late (within 3 days after initial meeting)</MenuItem>
            <MenuItem value={3}>Module was submitted significantly late (between 4 and 7 days after initial meeting)</MenuItem>
            <MenuItem value={0}>Module was not submitted</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 15 }}>
        <label>Tasks / activities thoughtfully completed with realistic examples and clear statements.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 15 }}>
        <FormControl fullWidth>
          <Select
            labelId="module 1 thoughtfully completed"
            id="module-1-completed"
            value={form.module1Completed}
            onChange={(event) => setForm({ ...form, module1Completed: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={15}>Module was thoughtfully completed by student</MenuItem>
            <MenuItem value={10}>Module was completed by student but requires more work</MenuItem>
            <MenuItem value={5}>Module completion needs significant improvement</MenuItem>
            <MenuItem value={0}>Module was not completed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Module #1 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {(form.module1Submitted && form.module1Submitted >= 0 ? form.module1Submitted : 0) +
          (form.module1Completed && form.module1Completed >= 0 ? form.module1Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Module #2</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Module was submitted on time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="module 2 submitted"
            id="module-2-submitted"
            value={form.module2Submitted}
            onChange={(event) => setForm({ ...form, module2Submitted: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={10}>Module was submitted on time</MenuItem>
            <MenuItem value={6}>Module was submitted late (within 3 days after initial meeting)</MenuItem>
            <MenuItem value={3}>Module was submitted significantly late (between 4 and 7 days after initial meeting)</MenuItem>
            <MenuItem value={0}>Module was not submitted</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 35 }}>
        <label>
          Worksheets and activities are thoughtfully completed with realistic exploration of options and clear notes and follow-up descriptions.
        </label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 35 }}>
        <FormControl fullWidth>
          <Select
            labelId="module 2 thoughtfully completed"
            id="module-2-completed"
            value={form.module2Completed}
            onChange={(event) => setForm({ ...form, module2Completed: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={15}>Module was thoughtfully completed by student</MenuItem>
            <MenuItem value={10}>Module was completed by student but requires more work</MenuItem>
            <MenuItem value={5}>Module completion needs significant improvement</MenuItem>
            <MenuItem value={0}>Module was not completed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Module #2 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {(form.module2Submitted && form.module2Submitted >= 0 ? form.module2Submitted : 0) +
          (form.module2Completed && form.module2Completed >= 0 ? form.module2Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Module #3</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Module was submitted on time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <FormControl fullWidth>
          <Select
            labelId="module 3 submitted"
            id="module-3-submitted"
            value={form.module3Submitted}
            onChange={(event) => setForm({ ...form, module3Submitted: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={10}>Module was submitted on time</MenuItem>
            <MenuItem value={6}>Module was submitted late (within 3 days after initial meeting)</MenuItem>
            <MenuItem value={3}>Module was submitted significantly late (between 4 and 7 days after initial meeting)</MenuItem>
            <MenuItem value={0}>Module was not submitted</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 15 }}>
        <label>Activities (budget, best bank account, etc.) were thoughtfully completed with realistic examples and clear statements.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 15 }}>
        <FormControl fullWidth>
          <Select
            labelId="module 3 thoughtfully completed"
            id="module-3-completed"
            value={form.module3Completed}
            onChange={(event) => setForm({ ...form, module3Completed: event.target.value })}
            disabled={disabled}
          >
            <MenuItem value={15}>Module was thoughtfully completed by student</MenuItem>
            <MenuItem value={10}>Module was completed by student but requires more work</MenuItem>
            <MenuItem value={5}>Module completion needs significant improvement</MenuItem>
            <MenuItem value={0}>Module was not completed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Module #3 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left" style={{ marginBottom: 20 }}>
        {(form.module3Submitted && form.module3Submitted >= 0 ? form.module3Submitted : 0) +
          (form.module3Completed && form.module3Completed >= 0 ? form.module3Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label style={{ fontWeight: 600 }}>Overall Total Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        {(form.gradingSheet1Completed && form.gradingSheet1Completed >= 0 ? form.gradingSheet1Completed : 0) +
          (form.gradingSheet1Submitted && form.gradingSheet1Submitted >= 0 ? form.gradingSheet1Submitted : 0) +
          (form.gradingSheet1Clarity && form.gradingSheet1Clarity >= 0 ? form.gradingSheet1Clarity : 0) +
          (form.module1Submitted && form.module1Submitted >= 0 ? form.module1Submitted : 0) +
          (form.module1Completed && form.module1Completed >= 0 ? form.module1Completed : 0) +
          (form.module2Submitted && form.module2Submitted >= 0 ? form.module2Submitted : 0) +
          (form.module2Completed && form.module2Completed >= 0 ? form.module2Completed : 0) +
          (form.module3Submitted && form.module3Submitted >= 0 ? form.module3Submitted : 0) +
          (form.module3Completed && form.module3Completed >= 0 ? form.module3Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
    </>
  );
}

Scoring.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
};
