import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import Card from '../../Card/Card.js';
import CardBody from '../../Card/CardBody.js';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import PersonalInfoIcon from '../../../assets/img/planner-personalinfo-icon.svg';
import HelpRequestIcon from '../../../assets/img/planner-help-icon.svg';
import ChecklistIcon from '../../../assets/img/planner-checklist-icon.svg';
import PlanningMeetingIcon from '../../../assets/img/planner-meeting-icon.svg';
import LifeSkillsIcon from '../../../assets/img/planner-lifeskills-icon.svg';
import TipsIcon from '../../../assets/img/planner-tips-icon.svg';
import HousingTradelifeIcon from '../../../assets/img/planner-housing-tradelife-icon.svg';
import ScholarshipsIcon from '../../../assets/img/planner-scholarships-icon.svg';
import RecommendationIcon from '../../../assets/img/planner-recommendations-icon.svg';
import ProactiveCheckinIcon from '../../../assets/img/proactive-contact-icon.svg';
import PleaseWait from '../../Shared/PleaseWait';

const Home = ({
  senior,
  alumni,
  roles,
  formatPhoneNumber,
  showReturnedAftercareApp,
  showReturnedScholarshipApp,
  aftercareSpecialist,
  showReturnedChecklist,
  showReturnedPlanningForm1,
  showReturnedPlanningForm2,
  setPage,
  navToHelpApps,
  navToAftercareApps,
  navToScholarshipApps,
  navToRecommendations,
  handleReturnToSeniorList,
  classes,
  navToCheckInApps,
}) => {
  const [working, setWorking] = useState(false);

  const handlePageChange = (page) => {
    setWorking(true);
    setPage(page);
    setWorking(false);
  };

  return (
    <div>
      <PleaseWait isLoading={working} />
      <Grid container spacing={3} direction="row" className={classes.menu}>
        {roles.includes('STUDENT') && senior && (
          <>
            <Grid item xs={12} md={6} lg={6} className={classes.welcomeGrid}>
              <Typography variant="h4" className={classes.welcome}>
                Welcome, {senior.first_name + ' ' + senior.last_name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.specialistGrid}>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Successful Futures Specialist:</label>{' '}
                <span className={classes.contactInfo}>
                  {aftercareSpecialist && aftercareSpecialist.user && aftercareSpecialist.user.id > 0
                    ? aftercareSpecialist.user.first_name + ' ' + aftercareSpecialist.user.last_name
                    : 'N/A'}
                </span>
              </aside>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Office Phone</label>{' '}
                <span className={classes.contactInfo}>
                  {aftercareSpecialist && aftercareSpecialist.office_phone ? formatPhoneNumber(aftercareSpecialist.office_phone) : 'N/A'}
                </span>
              </aside>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Cell Phone</label>{' '}
                <span className={classes.contactInfo}>
                  {aftercareSpecialist && aftercareSpecialist.cell_phone ? formatPhoneNumber(aftercareSpecialist.cell_phone) : 'N/A'}
                </span>
              </aside>
            </Grid>
            <Grid item xs={12} style={{ minheight: 0 }}>
              {showReturnedChecklist && <aside className={classes.error}>NOTE: You have a returned Checklist. Please review immediately!</aside>}
              {showReturnedPlanningForm1 && (
                <aside className={classes.error}>NOTE: You have a returned Planning Form #1. Please review immediately!</aside>
              )}
              {showReturnedPlanningForm2 && (
                <aside className={classes.error}>NOTE: You have a returned Planning Form #2. Please review immediately!</aside>
              )}
              {showReturnedAftercareApp && (
                <aside className={classes.error}>NOTE: You have a returned Trade Life/Housing application. Please review immediately!</aside>
              )}
              {showReturnedScholarshipApp && (
                <aside className={classes.error}>NOTE: You have a returned Scholarship application. Please review immediately!</aside>
              )}
            </Grid>
          </>
        )}

        {roles.includes('ALUMNI') && senior && (
          <Grid item xs={12}>
            {senior && (
              <div style={{ padding: '2% 10%' }}>
                <Typography variant="h4" className={classes.alumniWelcome}>
                  Welcome,{' '}
                  {senior && senior.alumni_updated_first_name && senior.alumni_updated_first_name.length > 0
                    ? senior.alumni_updated_first_name
                    : senior.first_name}{' '}
                  {senior && senior.alumni_updated_last_name && senior.alumni_updated_last_name.length > 0
                    ? senior.alumni_updated_last_name
                    : senior.last_name}
                </Typography>
                <p className={classes.alumniIntro}>
                  Successful Futures is designed to assist you in making and implementing informed educational and occupational choices. This includes
                  coordinating the distribution of need-based and merit scholarships to outstanding and qualified students pursuing undergraduate and
                  trade degrees, and coordinating the implementation of learning experiences (internships, apprenticeships, shadowing, volunteering,
                  and other career-oriented opportunities) to improve career decision making. We know you have many questions. We have the answers.{' '}
                  <strong>Click on the card below to get started!</strong>
                </p>
              </div>
            )}
            {showReturnedAftercareApp && (
              <aside className={classes.error}>NOTE: You have a returned Trade Life/Housing application. Please review immediately!</aside>
            )}
            {showReturnedScholarshipApp && (
              <aside className={classes.error}>NOTE: You have a returned Scholarship application. Please review immediately!</aside>
            )}
          </Grid>
        )}

        {!roles.includes('STUDENT') && !roles.includes('ALUMNI') && senior && (
          <>
            <Grid item xs={12} style={{ height: '25px' }} />
            <Grid item xs={12} md={6} lg={6} className={classes.adminWelcomeGrid}>
              <Typography variant="h4" className={classes.adminWelcome}>
                Forms for{' '}
                {senior && senior.alumni_updated_first_name && senior.alumni_updated_first_name.length > 0
                  ? senior.alumni_updated_first_name
                  : senior.first_name}{' '}
                {senior && senior.alumni_updated_last_name && senior.alumni_updated_last_name.length > 0
                  ? senior.alumni_updated_last_name
                  : senior.last_name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.specialistGrid}>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Successful Futures Specialist:</label>{' '}
                <span className={classes.contactInfo}>
                  {aftercareSpecialist && aftercareSpecialist.user && aftercareSpecialist.user.id > 0
                    ? aftercareSpecialist.user.first_name + ' ' + aftercareSpecialist.user.last_name
                    : 'N/A'}
                </span>
              </aside>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Office Phone</label>{' '}
                <span className={classes.contactInfo}>
                  {aftercareSpecialist && aftercareSpecialist.office_phone ? formatPhoneNumber(aftercareSpecialist.office_phone) : 'N/A'}
                </span>
              </aside>
              <aside style={{ textAlign: 'left' }}>
                <label className={classes.contactInfoLabel}>Cell Phone</label>{' '}
                <span className={classes.contactInfo}>
                  {aftercareSpecialist && aftercareSpecialist.cell_phone ? formatPhoneNumber(aftercareSpecialist.cell_phone) : 'N/A'}
                </span>
              </aside>
            </Grid>
          </>
        )}

        <Grid item xs={12} className={classes.cardGrid}>
          <nav onClick={(event) => handlePageChange('basicinfo')} className={classes.nav}>
            <Card className={classes.card}>
              <CardBody className={classes.cardBody}>
                <div className={classes.cardContainer}>
                  <img src={PersonalInfoIcon} alt="Personal Information Icon" className={classes.cardIcon} />
                  <section className={classes.menuTitle}>
                    <span className={classes.cardHeader}>Personal Information</span>
                  </section>
                  <aside className={classes.menuDesc}>
                    {alumni || roles.includes('ALUMNI')
                      ? 'View your personal information and update address and phone numbers.'
                      : 'View your student information and update graduation date, email, GPA, and test scores.'}
                  </aside>
                </div>
              </CardBody>
            </Card>
          </nav>
          {(alumni || roles.includes('ALUMNI')) && (
            <nav onClick={(event) => navToHelpApps()} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={HelpRequestIcon} alt="Help Requests Icon" className={classes.cardIcon} />
                    <section className={classes.menuTitle}>
                      <span className={classes.cardHeader}>Help Requests</span>
                    </section>
                    <aside className={classes.menuDesc}>
                      Contact Boys Town for help with financials, employment, housing, health care, or other questions.
                    </aside>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}
          {!alumni && !roles.includes('ALUMNI') && (
            <nav onClick={(event) => handlePageChange('checklist')} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={ChecklistIcon} alt="Checklist Icon" className={classes.cardIcon} />
                    <section className={classes.menuTitle}>
                      <span className={classes.cardHeader}>Senior Checklist</span>
                    </section>
                    <aside className={classes.menuDesc}>Maintain your checklist for completing your senior planning.</aside>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}
          {!alumni && !roles.includes('ALUMNI') && (
            <nav onClick={(event) => handlePageChange('planning1')} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={PlanningMeetingIcon} alt="Planning Meeting 1 Icon" className={classes.cardIcon} />
                    <section className={classes.menuTitle}>
                      <span className={classes.cardHeader}>Planning Meeting #1</span>
                    </section>
                    <aside className={classes.menuDesc}>
                      Complete your Senior Planning Form for meeting #1 held in the fall of your senior year.
                    </aside>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}
          {!alumni && !roles.includes('ALUMNI') && (
            <nav onClick={(event) => handlePageChange('planning2')} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={PlanningMeetingIcon} alt="Planning Meeting 2 Icon" className={classes.cardIcon} />
                    <section className={classes.menuTitle}>
                      <span className={classes.cardHeader}>Planning Meeting #2</span>
                    </section>
                    <aside className={classes.menuDesc}>
                      Complete your Senior Planning Form for meeting #2 held in the winter of your senior year.
                    </aside>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}
          {!alumni && !roles.includes('ALUMNI') && (
            <nav onClick={(event) => handlePageChange('life')} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={LifeSkillsIcon} alt="Advanced Life Skills Icon" className={classes.cardIcon} />
                    <section className={classes.menuTitle}>
                      <span className={classes.cardHeader}>Advanced Life Skills Schedule</span>
                    </section>
                    <aside className={classes.menuDesc}>View a schedule of Advanced Life Skills classes for the current academic year.</aside>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}
          {!alumni && !roles.includes('ALUMNI') && (
            <nav onClick={(event) => handlePageChange('tips')} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={TipsIcon} alt="Tips and Info Icon" className={classes.cardIcon} />{' '}
                    <section className={classes.menuTitle}>
                      <span className={classes.cardHeader}>Tips, Links and Information</span>
                    </section>
                    <aside className={classes.menuDesc}>View information including ACT test dates and educational resources.</aside>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}

          {senior && senior.user && senior.user.email && (
            <nav onClick={(event) => navToAftercareApps()} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={HousingTradelifeIcon} alt="Housing and Trade Life Icon" className={classes.cardIcon} />
                    <section className={classes.menuTitle}>
                      <span className={classes.cardHeader}>Housing / Trade Life Applications</span>
                    </section>
                    <aside className={classes.menuDesc}>Complete the application for Successful Futures and Housing services.</aside>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}

          {senior && senior.user && senior.user.email && (
            <nav onClick={(event) => navToScholarshipApps()} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={ScholarshipsIcon} alt="Scholarships Icon" className={classes.cardIcon} />
                    <section className={classes.menuTitle}>
                      <span className={classes.cardHeader}>Scholarship Applications</span>
                    </section>
                    <aside className={classes.menuDesc}>Complete an application for Boys Town scholarships.</aside>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}

          {!alumni && !roles.includes('ALUMNI') && (
            <nav onClick={(event) => navToRecommendations()} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={RecommendationIcon} alt="Letters of Recommendation Icon" className={classes.cardIcon} />
                    <section className={classes.menuTitle}>
                      <span className={classes.cardHeader}>Letters of Recommendation</span>
                    </section>
                    <aside className={classes.menuDesc}>View your pending letters of recommendation.</aside>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}
          {alumni && !roles.includes('ALUMNI') && (
            <nav onClick={(event) => navToCheckInApps()} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={ProactiveCheckinIcon} alt="Check-In Icon" className={classes.cardIcon} />
                    <section className={classes.menuTitle}>
                      <span className={classes.cardHeader}>Proactive Check-Ins</span>
                    </section>
                    <aside className={classes.menuDesc}>Check-In with Alumni 3 months and 12 months post-graduation to see how they are doing.</aside>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}
          {(alumni || roles.includes('ALUMNI')) && (
            <nav onClick={(event) => handlePageChange('checklist')} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer}>
                    <img src={ChecklistIcon} alt="Checklist Icon" className={classes.cardIcon} />
                    <section className={classes.menuTitle}>
                      <span className={classes.cardHeader}>Checklist</span>
                    </section>
                    <aside className={classes.menuDesc}>View the alum's senior checklist.</aside>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}
          {!roles.includes('STUDENT') && !roles.includes('ALUMNI') && (
            <nav onClick={(event) => handleReturnToSeniorList()} className={classes.nav}>
              <Card className={classes.card}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.cardContainer} style={{ paddingTop: '20%' }}>
                    <KeyboardReturnIcon style={{ fontSize: '3.00rem' }} />
                    <div>{alumni ? 'Return to Alumni List' : 'Return to Senior List'}</div>
                  </div>
                </CardBody>
              </Card>
            </nav>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

Home.propTypes = {
  senior: PropTypes.object,
  alumni: PropTypes.bool,
  roles: PropTypes.array,
  formatPhoneNumber: PropTypes.func,
  showReturnedAftercareApp: PropTypes.bool,
  showReturnedScholarshipApp: PropTypes.bool,
  aftercareSpecialist: PropTypes.object,
  showReturnedChecklist: PropTypes.bool,
  showReturnedPlanningForm1: PropTypes.bool,
  showReturnedPlanningForm2: PropTypes.bool,
  setPage: PropTypes.func,
  navToHelpApps: PropTypes.func,
  navToAftercareApps: PropTypes.func,
  navToScholarshipApps: PropTypes.func,
  navToRecommendations: PropTypes.func,
  handleReturnToSeniorList: PropTypes.func,
  classes: PropTypes.object.isRequired,
  navToCheckInApps: PropTypes.func,
};

export default Home;
