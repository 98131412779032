import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import LuxonUtils from '@date-io/luxon';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Box,
  Grid,
  FormControl,
  TextField,
  Typography,
  Button,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import PleaseWait from '../Shared/PleaseWait';
import { callApi, BadRequestError } from '../../util/apiWrapper';
import PhoneInput from '../Shared/PhoneInput';

const styles = makeStyles((theme) => ({
  buttonWidth: {
    width: 130,
  },
  sectionSpacer: {
    margin: '20px 0',
  },
  datepicker: {
    width: '230px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  checkRowSpacer: {
    [theme.breakpoints.up('lg')]: {
      height: 75,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      height: 110,
    },
    [theme.breakpoints.down('md')]: {
      height: 75,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.90rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.90rem',
      },
    },
  },
  boxContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '15%',
      paddingRight: '15%',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  reminder: {
    fontSize: '0.90rem',
    fontWeight: 600,
    margin: '10px 0',
    lineHeight: '1.20rem',
    color: 'blue',
  },
  contactInfo: {
    fontSize: '0.80rem',
    fontWeight: 600,
    margin: '40px 0 15px 0',
    lineHeight: '1.15rem',
  },
  consentCheckbox: {
    fontSize: '0.85rem',
    fontWeight: 500,
    lineHeight: '1.10rem',
  },
  inputTip: {
    fontSize: '0.80rem',
    fontWeight: 300,
    margin: '2px 0 3px 0',
    lineHeight: '1.20rem',
    color: 'gray',
  },
}));

const PROGRAMS = ['Family Home Program', 'Residential Treatment'];

const AccessRequest = () => {
  const minDate = new Date('1917-12-01');
  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = styles();
  const [alreadySubmitted, setAlreadySubmitted] = useState(window.sessionStorage.getItem('accessRequestSubmitted') === 'true');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenError, setTokenError] = useState('');
  const [apiError, setApiError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [form, setForm] = useState({
    email: '',
    phone: '',
    first_name: '',
    last_name: '',
    dob: null,
    admission_date: null,
    departure_date: null,
    program_attended: [],
    recaptcha_token: null,
    note: '',
    consent: false,
  });
  const [phoneIsValid, setPhoneIsValid] = useState(true);

  const validateEmail = useCallback((email) => {
    const gmailRegex = /gmail.com$|test.com$/i;
    return email.match(gmailRegex);
  }, []);

  useEffect(() => {
    if (form.email.length > 0 && !validateEmail(form.email)) {
      setEmailError('Email must be a gmail address (@gmail.com).');
    }
  }, [form.email, validateEmail]);

  useEffect(() => {
    if (form.email.length === 0 || validateEmail(form.email)) {
      setEmailError('');
    }
  }, [form.email, emailError, validateEmail]);

  useEffect(() => {
    if (form.recaptcha_token && form.recaptcha_token.length > 0 && tokenError.length > 0) {
      setTokenError('');
    }
  }, [form.recaptcha_token, tokenError]);

  const onBeforeSubmit = async (e) => {
    e.preventDefault();
    if (emailError.length > 0) {
      return;
    }
    if (form.dob === null || form.dob < minDate) {
      console.log('invalid dob');
      return;
    }
    if (form.admission_date !== null && form.admission_date < minDate) {
      console.log('invalid admission date');
      return;
    }
    if (form.departure_date !== null && form.departure_date < minDate) {
      console.log('invalid departure date');
      return;
    }
    if (!form.recaptcha_token || form.recaptcha_token.length === 0) {
      setTokenError('Please complete the reCAPTCHA to continue.');
      return;
    }
    await sendRequest();
  };

  const sendRequest = async () => {
    let data = JSON.parse(JSON.stringify(form));
    data.program_attended = form.program_attended.join(',');
    try {
      setIsLoading(true);
      await callApi(null, 'accessrequest', 'POST', {
        data: data,
      });

      await sendAccessRequestSubmittedEmail();

      setIsLoading(false);
      setDialogOpen(true);
    } catch (error) {
      if (error instanceof BadRequestError) {
        setIsLoading(false);
        setDialogOpen(true);
        const msg = JSON.parse(error.message);
        setApiError(msg.responseStatus.message);
        return;
      } else {
        setApiError('An unexpected error has occurred. Please try again at a later time.');
        setIsLoading(false);
      }
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    window.location.href = '/';
  };

  const sendAccessRequestSubmittedEmail = async (id, request_id) => {
    try {
      await callApi(null, 'accessrequestsubmittedemail', 'POST', {});
    } catch (error) {
      alert('error!: ', error);
      console.log('Error sending access request submitted email:', error);
    }
  };

  return (
    <Box className={classes.boxContainer}>
      <PleaseWait isLoading={isLoading} />
      <Typography variant="h5" align="center" gutterBottom style={{ margin: '50px 0 15px 0' }}>
        Apply for a Boys Town Successful Futures Account
      </Typography>
      <Typography variant="h6" align="center" gutterBottom className={classes.reminder} style={{ margin: '0 0 50px 0' }}>
        NOTE: Only 'Gmail' email addresses will be accepted when joining Successful Futures.
      </Typography>
      {alreadySubmitted && <p>It looks like you've already submitted a request. We'll review it and get back to you as soon as possible.</p>}
      {!alreadySubmitted && (
        <form onSubmit={onBeforeSubmit}>
          <Grid container align="left">
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  id="email"
                  type="email"
                  size="small"
                  required
                  label="Gmail Email Address"
                  variant="outlined"
                  helperText={emailError}
                  error={emailError.length > 0}
                  value={form.email}
                  onChange={(event) => {
                    setForm({ ...form, email: event.target.value });
                  }}
                  InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
                  className={classes.root}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} className={classes.sectionSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="first-name"
                  size="small"
                  label="First Name"
                  variant="outlined"
                  required
                  value={form.first_name}
                  onChange={(event) => setForm({ ...form, first_name: event.target.value })}
                  InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
                  className={classes.root}
                />
              </FormControl>
            </Grid>
            <Grid item xs={false} md={1} className={classes.sectionSpacer}></Grid>
            <Grid item xs={12} md={3} className={classes.sectionSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="last-name"
                  size="small"
                  label="Last Name"
                  variant="outlined"
                  required
                  value={form.last_name}
                  onChange={(event) => setForm({ ...form, last_name: event.target.value })}
                  InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
                  className={classes.root}
                />
              </FormControl>
            </Grid>
            <Grid item xs={false} md={1} className={classes.sectionSpacer}></Grid>
            <Grid item xs={8} md={3} className={classes.sectionSpacer}>
              <FormControl fullWidth error={!phoneIsValid}>
                <PhoneInput
                  id="phone"
                  label="Phone Number"
                  variant="outlined"
                  value={form.phone}
                  handleChange={(val, isValid) => {
                    setForm({ ...form, phone: val });
                    setPhoneIsValid(isValid);
                  }}
                />
                <FormHelperText id="my-helper-text">{!phoneIsValid && 'Please enter a valid phone number.'}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 10 }}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    id="dob"
                    fullWidth
                    required
                    minDate={minDate}
                    label="Date of Birth"
                    disableFuture
                    value={form.dob}
                    onChange={(date) => {
                      setForm({ ...form, dob: date ? date : null });
                    }}
                    format="MM/dd/yyyy"
                    className={classes.datepicker}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <div className={classes.inputTip}>(Enter mm/dd/yyyy or 01/02/3456)</div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 10 }}>
              <FormControl fullWidth component="fieldset">
                <FormLabel style={{ textAlign: 'left' }}>Program Attended</FormLabel>
                <FormGroup row>
                  {PROGRAMS.map((p, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            key={i}
                            checked={form.program_attended.indexOf(p) > -1}
                            color="primary"
                            onChange={(e) => {
                              if (e.target.checked) {
                                form.program_attended.push(p);
                                setForm({ ...form, program_attended: form.program_attended });
                              } else {
                                const found = form.program_attended.findIndex((x) => x === p);
                                form.program_attended.splice(found, 1);
                                setForm({ ...form, program_attended: form.program_attended });
                              }
                            }}
                            name={p.replace(' ', '_')}
                          />
                        }
                        label={p}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 10 }}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    id="admission-date"
                    label="Admission Date"
                    minDate={minDate}
                    disableFuture
                    value={form.admission_date}
                    views={['year', 'month']}
                    onChange={(date) => {
                      setForm({ ...form, admission_date: date ? date : null });
                    }}
                    format="MM/yyyy"
                    className={classes.datepicker}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <div className={classes.inputTip}>(Enter mm/yyyy or 01/2345)</div>
            </Grid>
            <Grid item xs={11} md={6} style={{ padding: 10 }}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    id="departure-date"
                    label="Departure Date"
                    disableFuture
                    minDate={minDate}
                    value={form.departure_date}
                    // views={['month']}
                    views={['year', 'month']}
                    onChange={(date) => {
                      setForm({ ...form, departure_date: date ? date : null });
                    }}
                    format="MM/yyyy"
                    className={classes.datepicker}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <div className={classes.inputTip}>(Enter mm/yyyy or 01/2345)</div>
            </Grid>
            <Grid item xs={12} className={classes.sectionSpacer}>
              <FormControl fullWidth>
                <TextField
                  id="note"
                  size="small"
                  label="Additional Information"
                  variant="outlined"
                  value={form.note}
                  onChange={(event) => {
                    setForm({ ...form, note: event.target.value });
                  }}
                  InputProps={{ maxLength: 500, classes: { input: classes.textField } }}
                  className={classes.root}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.sectionSpacer}>
              <FormControl fullWidth>
                <FormControlLabel
                  style={{ display: 'table' }}
                  control={
                    <div style={{ display: 'table-cell' }}>
                      <Checkbox color="default" />
                    </div>
                  }
                  label={
                    <Typography className={classes.consentCheckbox}>
                      I voluntarily give consent to Boys Town Successful Futures Department to contact me for the purpose of service provision. I
                      acknowledge that no guarantees have been made to me as to the availability and results of services. I am aware that this consent
                      can be revoked in writing at any time. I understand that Boys Town will protect my privacy and rights and will not use my
                      information for any other purpose. I understand that Boys Town follows a policy of non-discrimination on the basis of race,
                      color, national origin, gender, sexual orientation, age, or disability. I understand that my signature indicates that all the
                      above information is true to the best of my knowledge.*
                    </Typography>
                  }
                  onChange={(event) => {
                    setForm({ ...form, consent: event.target.value });
                  }}
                  checked={form.consent}
                />
              </FormControl>
            </Grid>
            <Grid item xs={false} md={8} style={{ paddingTop: '25px' }}></Grid>
            <Grid item xs={12} md={4} style={{ padding: '50px 0 15px 12px' }} className={classes.sectionSpacer}>
              <ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`} onChange={(token) => setForm({ ...form, recaptcha_token: token })} />
            </Grid>
            <Grid item xs={12} align="right" className={classes.sectionSpacer}>
              <Button
                className={classes.buttonWidth}
                style={{ marginRight: '10px' }}
                color="default"
                variant="contained"
                onClick={(e) => handleDialogClose()}
              >
                Cancel
              </Button>
              <Button className={classes.buttonWidth} color="primary" variant="contained" type="submit" disabled={form.consent === false}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{apiError && apiError.length > 0 ? 'Warning' : 'Access Request Sent'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {apiError && apiError.length > 0 ? apiError : 'Your access request had been sent! You will receive an email when it has been processed.'}
          </DialogContentText>
          <DialogContentText id="dialog-contact-info">
            <aside className={classes.contactInfo}>
              Contact the Successful Futures Team:
              <br />
              <a href="mailto:successfulfutures@boystown.org">SuccessfulFutures@boystown.org</a> / 531-355-3060
            </aside>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccessRequest;
