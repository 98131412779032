import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, FormControl, TextField, TextareaAutosize, Box, Chip, Tooltip, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { logException, appInsights } from '../../services/appInsights';
import { dateDiffYears, dateToApi, dateOnlyFormat } from '../../util/dateUtil';
import ExpandingList2 from '../ExpandingList/ExpandingList2';
import ExpandingList3 from '../ExpandingList/ExpandingList3';
import Needs from './ScholarshipComponents/Needs';
import Instructions from './ScholarshipComponents/Instructions';
import AppSection from './ScholarshipComponents/AppSection';
import Scoring from './Scoring';
import AlumniAppSection from './ScholarshipComponents/AlumniAppSection';
import AlumniSchoolProgram from './ScholarshipComponents/AlumniSchoolProgram';
import AlumniFinancialInfo from './ScholarshipComponents/AlumniFinancialInfo';
import { btMediumGrayColor, btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import PostGradSection from './ScholarshipComponents/PostGradSection';
import { convertFile, arrayBufferToBase64, titleize, isNumeric } from '../../util/stringUtil';
import { callApi } from '../../util/apiWrapper';
import FamilyFinancialSupport from './ScholarshipComponents/FamilyFinancialSupport';
import RequiredInfoDialog from '../Shared/RequiredInfoDialog';
import CurrentEmployment from './ScholarshipComponents/CurrentEmployment';
import EstimatedExpenses from './ScholarshipComponents/EstimatedExpenses';
import InfoIcon from '@material-ui/icons/Info';
import AwardDialog from '../ScholarshipApplications/ScholarshipDialogs/AwardDialog';
import { logAudit } from '../../util/auditUtil';
import { calculatePlanningScore, getScholarshipNameFromId, updateAwardTotals } from '../../util/scholarshipsUtil';

const formStyles = makeStyles((theme) => ({
  sectionTitle: {
    lineHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.00rem',
      fontWeight: 600,
      margin: '15px 0 5px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
      margin: '25px 0 5px 0',
    },
  },
  paper: {
    padding: '10px',
  },
  reminder: {
    color: 'blue',
    fontSize: '1.05rem',
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  rowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 30,
    },
    [theme.breakpoints.down('sm')]: {
      height: 30,
    },
  },
  rowSpacer50: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
    [theme.breakpoints.down('sm')]: {
      height: 50,
    },
  },
  textRowSpacer: {
    paddingRight: '10px',
    paddingTop: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '5px',
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      minHeight: 30,
      paddingTop: 15,
      overflow: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 30,
      paddingTop: 10,
      overflow: 'auto',
    },
  },
  radioGroupLabel: {
    paddingTop: 7,
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  subtleBold: {
    fontWeight: 500,
    color: btMediumGrayColor,
  },
  datepicker: {
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  textAreaFont: {
    fontSize: '1.00rem',
    fontFamily: 'Source Sans Pro, Helvetica, Roboto, Arial, sans-serif',
    padding: 10,
  },
  textArea5RowSpacer: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 115,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 105,
    },
  },
  textArea10RowSpacer: {
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 215,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 205,
    },
  },
  instructions: {
    color: '#000',
  },
  error: {
    color: '#FA0000',
    fontSize: '0.90rem',
    fontWeight: 600,
  },
  essayOptions: {
    fontSize: '0.90rem',
    fontWeight: 500,
    color: btNavyBlueColor,
  },
  buttonWidth: {
    width: 110,
  },
  floatingButton: {
    width: 110,
    position: 'absolute',
    right: 15,
    bottom: 15,
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  buttonSpacer: {
    height: 50,
    marginTop: 20,
  },
  dispositionNote: {
    fontSize: '1.05rem',
    border: '1px solid blue',
    padding: 15,
    backgroundColor: '#FFFAF0',
  },
  gradingSectionTitle: {
    fontSize: '1.125rem',
    fontWeight: 600,
    margin: '20px 0 5px 0',
  },
  gradeLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      minHeight: 30,
      paddingTop: 15,
      paddingRight: 5,
      overflow: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 30,
      paddingTop: 17,
      paddingRight: 5,
      overflow: 'auto',
    },
  },
  gpaSpacer: {
    paddingLeft: 5,
    [theme.breakpoints.down('md')]: {
      minHeight: 30,
      paddingTop: 15,
      paddingRight: 5,
      overflow: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 30,
      paddingTop: 17,
      paddingRight: 5,
      overflow: 'auto',
    },
  },
  marginRight40: {
    marginRight: 40,
  },
  gpaNote: {
    fontSize: '0.80rem',
    fontWeight: 300,
  },
  labelSpacer: {
    padding: '10px 0 2px 0',
  },
  labelXLSpacer: {
    padding: '35px 0 0 0',
  },
  printableTableHeader: {
    padding: '10px 0 0 0',
    fontWeight: 600,
  },
  printableTableHeight: {
    padding: '5px 0 0 0',
  },
}));

const ScholarshipApp = observer((props) => {
  const store = props.SeniorStore;
  const auth = props.AuthStore;
  const { handleSaveReturn } = props;

  const [disabled, setDisabled] = useState(!auth.roles.includes('STUDENT'));
  const [alumnidisabled, setAlumniDisabled] = useState(!auth.roles.includes('ALUMNI'));
  const classes = formStyles();
  const [originalStatus, setOriginalStatus] = useState('');
  const [status, setStatus] = useState('created');
  const [dispositionReason, setDispositionReason] = useState('');
  const [lettersOfRecError, setLetterOfRecUploadError] = useState(null);
  const [alumniFAFSAError, setAlumniFAFSAError] = useState(null);
  const [alumniTranscriptError, setAlumniTranscriptError] = useState(null);
  const [alumniEnrollmentError, setAlumniEnrollmentError] = useState(null);
  const [senior, setSenior] = useState(null);
  const [uploadError, setUploadError] = useState();
  const [requiredFields, setRequiredFields] = useState(null);
  const [requiredFieldsIntro, setRequiredFieldsIntro] = useState('');
  const [showRequiredFieldsDialog, setShowRequiredFieldsDialog] = useState(false);
  const [savedFormJson, setSavedFormJson] = useState(null);
  const [youthStrengths, setYouthStrengths] = useState('');
  const [ssmModelCategory, setSsmModelCategory] = useState('');
  const [applicationScore, setApplicationScore] = useState('');
  const [essayScore, setEssayScore] = useState('');
  const [planningMeeting1Score, setPlanningMeeting1Score] = useState('');
  const [planningMeeting2Score, setPlanningMeeting2Score] = useState('');
  const [spiritualityScore, setSpiritualityScore] = useState('');
  const [pellAmount, setPellAmount] = useState(0);
  const [gradingGPA, setGradingGPA] = useState(0);
  const [gradingFinancialNeed, setGradingFinancialNeed] = useState(-1);
  const [gradingLengthOfStay, setGradingLengthOfStay] = useState(0);
  const [gradingEssayVoice, setGradingEssayVoice] = useState(-1);
  const [gradingEssayInspiration, setGradingEssayInspiration] = useState(-1);
  const [gradingEssayLanguage, setGradingEssayLanguage] = useState(-1);
  const [gradingEssayCompleted, setGradingEssayCompleted] = useState(-1);
  const [gradingCitizenshipLetters, setGradingCitizenshipLetters] = useState(-1);
  const [gradingCitizenshipActivities, setGradingCitizenshipActivities] = useState(-1);
  const [gradingCitizenshipRoleModel, setGradingCitizenshipRoleModel] = useState(-1);
  const [applicantType, setApplicantType] = useState('');

  const [form, setForm] = useState({
    act: null,
    asvab: null,
    ageAtGraduation: null,
    btPrograms: [],
    extraCurricularActivites: [{ schoolYear: '', description: '' }],
    leadershipPositions: [{ position: '', description: '' }],
    state: '',
    housingPlan: '',
    studentAidReportCompleted: 'no',
    fafsaCompleted: 'no',
    jobCorpsAppCompleted: 'no',
    essay: null,
    lettersOfRec: [],
    alumniSchoolName: '',
    alumniSchoolWebsite: '',
    alumniSchoolAddress: '',
    alumniSchoolCity: '',
    alumniSchoolState: '',
    alumniSchoolZip: '',
    alumniSchoolPhone: '',
    alumniStudentID: '',
    alumniAdvisorName: '',
    alumniAdvisorPhone: '',
    alumniAdvisorEmail: '',
    alumniScholarshipMailingAddress: '',
    alumniScholarshipMailingCity: '',
    alumniScholarshipMailingState: '',
    alumniScholarshipMailingZip: '',
    alumniDegree: '',
    alumniAreaOfStudy: '',
    alumniPlannedCompletionDate: null,
    actScore: null,
    alumniHistoryDiploma: false,
    alumniHistoryTrade: false,
    alumniHistory4Year: false,
    alumniHistoryMilitary: false,
    alumniHistoryJobCorps: false,
    alumniHistoryNone: false,
    alumniExtraCurricularActivities: [{ schoolYear: '', description: '' }],
    alumniActivitiesNone: false,
    alumniLeadershipPositions: [{ position: '', description: '' }],
    alumniLeadershipPositionsNone: false,
    alumniOtherScholarships: [{ scholarshipName: '', institution: '', scholarshipAmount: 0, scholarshipStatus: '' }],
    alumniOtherScholarshipsNone: false,
    alumniExpensesTuition: 0,
    alumniExpensesBooks: 0,
    alumniExpensesFees: 0,
    alumniExpensesOther: 0,
    alumniExpensesOtherExplanation: '',
    alumniFafsaCompleted: '',
    alumniPellEligible: '',
    alumniFAFSA: [],
    alumniTranscriptAvailable: '',
    alumniTransriptNotAvailableExplanation: '',
    alumniTranscript: [],
    alumniEnrollmentLetterAvailable: '',
    alumniEnrollmentNotAvailableExplanation: '',
    alumniEnrollment: [],
    alumniSignature: '',
  });

  // Awards
  const [application, setApplication] = React.useState({});
  const [showAwardsDialog, setShowAwardsDialog] = React.useState(false);
  const [seniorPlanning1, setSeniorPlanning1] = React.useState({});
  const [seniorPlanning2, setSeniorPlanning2] = React.useState({});
  const [awardPendingTotal, setAwardPendingTotal] = React.useState(0);
  const [awardAwardedTotal, setAwardAwardedTotal] = React.useState(0);
  const [awardTotal, setAwardTotal] = React.useState(0);
  const [seniorPlanningScore, setSeniorPlanningScore] = React.useState(0);
  const [showAwardsButton, setShowAwardsButton] = React.useState(false);
  const [showAddAward, setShowAddAward] = React.useState(false);
  const [showCompleteDialog, setShowCompleteDialog] = React.useState(false);

  useEffect(() => {
    if (store.senior) {
      setSenior(store.senior);
      setForm((f) => (f = { ...f, gradDate: dateOnlyFormat(store.senior.graduation_date) }));
      setForm(
        (f) =>
          (f = {
            ...f,
            ageAtGraduation:
              store.senior.graduation_date > /Date(0)/ && dateDiffYears(dateToApi(store.senior.dob), dateToApi(store.senior.graduation_date)),
          })
      );
      setForm((f) => (f = { ...f, actScore: store.senior.act }));
      setForm((f) => (f = { ...f, asvabScore: store.senior.asvab }));
    }
  }, [store.senior]);

  useEffect(() => {
    console.log('..........scholarshipApp useEffect');
    if (store.scholarshipApp && store.scholarshipApp.application && store.scholarshipApp.application !== null) {
      const tempForm = JSON.parse(store.scholarshipApp.application);
      if (!tempForm.lettersOfRec) {
        tempForm.lettersOfRec = [];
      }

      setForm((f) => (f = tempForm));
      setSavedFormJson(JSON.stringify(form));
      setStatus(store.scholarshipApp.status);
      setOriginalStatus(store.scholarshipApp.status);
      setDispositionReason(store.scholarshipApp.disposition_reason ? store.scholarshipApp.disposition_reason : '');
      setYouthStrengths(store.scholarshipApp.youth_strengths ? store.scholarshipApp.youth_strengths : '');
      setSsmModelCategory(store.scholarshipApp.ssm_model_category ? store.scholarshipApp.ssm_model_category : '');
      setApplicationScore(store.scholarshipApp.application_score ? store.scholarshipApp.application_score : '');
      setEssayScore(store.scholarshipApp.essay_score ? store.scholarshipApp.essay_score : '');
      setPlanningMeeting1Score(store.scholarshipApp.planning_meeting_1_score ? store.scholarshipApp.planning_meeting_1_score : '');
      setPlanningMeeting2Score(store.scholarshipApp.planning_meeting_2_score ? store.scholarshipApp.planning_meeting_2_score : '');
      setSpiritualityScore(store.scholarshipApp.spirituality_score ? store.scholarshipApp.spirituality_score : '');
      setPellAmount(store.scholarshipApp.pell_amount ? store.scholarshipApp.pell_amount : 0);
      setApplicantType(store.scholarshipApp.applicant_type);
      setGradingGPA(store.scholarshipApp.gpa_score ? parseInt(store.scholarshipApp.gpa_score, 10) : -1);
      setGradingFinancialNeed(store.scholarshipApp.financial_need_score ? parseInt(store.scholarshipApp.financial_need_score, 10) : -1);
      setGradingLengthOfStay(store.scholarshipApp.length_of_stay_score ? parseInt(store.scholarshipApp.length_of_stay_score, 10) : -1);
      setGradingEssayVoice(store.scholarshipApp.essay_voice_score ? parseInt(store.scholarshipApp.essay_voice_score, 10) : -1);
      setGradingEssayInspiration(store.scholarshipApp.essay_inspiration_score ? parseInt(store.scholarshipApp.essay_inspiration_score, 10) : -1);
      setGradingEssayLanguage(store.scholarshipApp.essay_word_score ? parseInt(store.scholarshipApp.essay_word_score, 10) : -1);
      setGradingEssayCompleted(store.scholarshipApp.essay_completed_score ? parseInt(store.scholarshipApp.essay_completed_score, 10) : -1);
      setGradingCitizenshipLetters(
        store.scholarshipApp.citizenship_letters_score ? parseInt(store.scholarshipApp.citizenship_letters_score, 10) : -1
      );
      setGradingCitizenshipActivities(
        store.scholarshipApp.citizenship_activities_score ? parseInt(store.scholarshipApp.citizenship_activities_score, 10) : -1
      );
      setGradingCitizenshipRoleModel(
        store.scholarshipApp.citizenship_rolemodel_score ? parseInt(store.scholarshipApp.citizenship_rolemodel_score, 10) : -1
      );

      if (store.scholarshipApp.status === 'denied' || store.scholarshipApp.status === 'awarded' || store.scholarshipApp.status === 'submitted') {
        setDisabled(true);
      }
      if (store.scholarshipApp.status === 'denied' || store.scholarshipApp.status === 'awarded' || store.scholarshipApp.status === 'submitted') {
        setAlumniDisabled(true);
      }

      setApplication(store.scholarshipApp);
      setShowAwardsButton(true);
    }
  }, [store.scholarshipApp, disabled]);

  useEffect(() => {
    try {
      const plan1 = JSON.parse(seniorPlanning1.planning_form_1_data);
      const plan2 = JSON.parse(seniorPlanning2.planning_form_2_data);
      const planningScore = calculatePlanningScore(plan1, plan2);
      setSeniorPlanningScore(planningScore);
    } catch (e) {
      console.warn('Error reading planning form scores: ', e.error);
    }
  }, [seniorPlanning1, seniorPlanning2]);

  useEffect(() => {
    const autoSaveScholarshipApp = async () => {
      if (savedFormJson !== JSON.stringify(form)) {
        try {
          const item = {
            id: store.scholarshipAppId,
            userId: store.senior.userId,
            application: form,
            status: status,
            submitted_date: store.scholarshipApp.submitted_date,
            disposition_reason: dispositionReason,
            youth_strengths: youthStrengths,
            ssm_model_category: ssmModelCategory,
            application_score: applicationScore,
            essay_score: essayScore,
            planning_meeting_1_score: planningMeeting1Score,
            planning_meeting_2_score: planningMeeting2Score,
            spirituality_score: spiritualityScore,
            pell_amount: pellAmount,
            gpa_score: gradingGPA,
            financial_need_score: gradingFinancialNeed,
            length_of_stay_score: gradingLengthOfStay,
            essay_voice_score: gradingEssayVoice,
            essay_inspiration_score: gradingEssayInspiration,
            essay_word_score: gradingEssayLanguage,
            essay_completed_score: gradingEssayCompleted,
            citizenship_letters_score: gradingCitizenshipLetters,
            citizenship_activities_score: gradingCitizenshipActivities,
            citizenship_rolemodel_score: gradingCitizenshipRoleModel,
            status_date: new Date(),
            applicant_type: showAlumniAppForAdmin() || showAppForAlumni() ? 'alumni' : 'senior',
            status_by: auth.user.result.first_name + ' ' + auth.user.result.last_name,
          };
          await (auth, 'scholarship', 'PUT', { item });
          setSavedFormJson(JSON.stringify(form));
          appInsights.trackTrace({ message: `Auto saved scholarship form for ${auth.user.result.email}` });
        } catch (error) {
          logException(error);
          store.setSearching(false);
          store.setLoading(false);
          console.log('Error auto saving scholarship application:', error);
        }
      }
    };

    if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
      const interval = setInterval(() => {
        autoSaveScholarshipApp();
      }, 300000);
      return () => clearInterval(interval);
    }
  }, [
    auth.roles,
    auth,
    dispositionReason,
    youthStrengths,
    ssmModelCategory,
    applicationScore,
    essayScore,
    planningMeeting1Score,
    planningMeeting2Score,
    spiritualityScore,
    pellAmount,
    form,
    savedFormJson,
    status,
    store,
  ]);

  const downloadFile = async (file) => {
    let a = document.createElement('a'); //Create <a>
    const browserFile = convertFile({ file: file.data, file_type: file.file_type, file_name: file.filename });
    a.href = `data:${browserFile.type};base64,${arrayBufferToBase64(await browserFile.arrayBuffer())}`;
    a.download = browserFile.name;
    a.click();
  };

  const removeEssay = () => {
    (showAppForSenior() || showAppForAlumni()) && setForm({ ...form, essay: null });
  };

  const removeFAFSAFile = (filename) => {
    showAppForAlumni() && setForm({ ...form, alumniFAFSA: form.alumniFAFSA.filter((item) => item.filename !== filename) });
  };

  const removeTranscriptFile = (filename) => {
    showAppForAlumni() && setForm({ ...form, alumniTranscript: form.alumniTranscript.filter((item) => item.filename !== filename) });
  };

  const removeEnrollmentFile = (filename) => {
    showAppForAlumni() && setForm({ ...form, alumniEnrollment: form.alumniEnrollment.filter((item) => item.filename !== filename) });
  };

  const checkExtensionForDocument = (file) => {
    if (file[0].size > 5 * 1024 * 1024) {
      return false;
    }
    return /\.(doc|docx|pdf|txt)$/i.test(file[0].name);
  };

  const handleUnderstand = () => {
    store.setAppCreated(false);
  };

  const setAppStatus = (val) => {
    setDispositionReason('');
    setStatus(val);
  };

  const confirmFileUpload = async (file) => {
    try {
      if (checkExtensionForDocument(file)) {
        const data = arrayBufferToBase64(await file[0].arrayBuffer());
        const filedetails = {
          'data': data,
          'file_type': file[0].type,
          'filename': file[0].name,
        };
        setForm({ ...form, essay: filedetails });
        setUploadError('');
      } else {
        setLetterOfRecUploadError(
          'Error: The file could not be uploaded. Only Word Documents, Text Files, and PDF files can be uploaded (Max size 5 MB).'
        );
      }
    } catch (error) {
      logException(error);
      console.log('Error setting essay details:', error);
    }
  };

  const confirmFAFSAUpload = async (file) => {
    if (form.alumniFAFSA.findIndex((x) => x.filename === file[0].name) > -1) {
      setAlumniFAFSAError(`FAFSA file with name ${file[0].name} is already uploaded.`);
      return;
    }
    try {
      if (checkExtensionForDocument(file)) {
        const data = arrayBufferToBase64(await file[0].arrayBuffer());
        const filedetails = {
          'data': data,
          'file_type': file[0].type,
          'filename': file[0].name,
        };

        let tempArray = [...form.alumniFAFSA, filedetails];
        setForm({ ...form, alumniFAFSA: tempArray });
        setAlumniFAFSAError('');
      } else {
        setAlumniFAFSAError(
          'Error: FAFSA file could not be uploaded. Only Word Documents, Text Files, and PDF files can be uploaded (Max size 5 MB).'
        );
      }
    } catch (error) {
      logException(error);
      console.log('Error uploading FAFSA file:', error);
    }
  };

  const confirmTranscriptUpload = async (file) => {
    if (form.alumniTranscript.findIndex((x) => x.filename === file[0].name) > -1) {
      setAlumniTranscriptError(`Official Transcript with name ${file[0].name} is already uploaded.`);
      return;
    }
    try {
      if (checkExtensionForDocument(file)) {
        const data = arrayBufferToBase64(await file[0].arrayBuffer());
        const filedetails = {
          'data': data,
          'file_type': file[0].type,
          'filename': file[0].name,
        };

        let tempArray = [...form.alumniTranscript, filedetails];
        setForm({ ...form, alumniTranscript: tempArray });
        setAlumniTranscriptError('');
      } else {
        setAlumniTranscriptError(
          'Error: The Official Transcript could not be uploaded. Only Word Documents, Text Files, and PDF files can be uploaded (Max size 5 MB).'
        );
      }
    } catch (error) {
      logException(error);
      console.log('Error uploading Official Transcript file:', error);
    }
  };

  const confirmEnrollmentUpload = async (file) => {
    if (form.alumniEnrollment.findIndex((x) => x.filename === file[0].name) > -1) {
      setAlumniEnrollmentError(`Proof of Enrollment with name ${file[0].name} is already uploaded.`);
      return;
    }
    try {
      if (checkExtensionForDocument(file)) {
        const data = arrayBufferToBase64(await file[0].arrayBuffer());
        const filedetails = {
          'data': data,
          'file_type': file[0].type,
          'filename': file[0].name,
        };

        let tempArray = [...form.alumniEnrollment, filedetails];
        setForm({ ...form, alumniEnrollment: tempArray });
        setAlumniEnrollmentError('');
      } else {
        setAlumniEnrollmentError(
          'Error: The Proof of Enrollment could not be uploaded. Only Word Documents, Text Files, and PDF files can be uploaded (Max size 5 MB).'
        );
      }
    } catch (error) {
      logException(error);
      console.log('Error uploading Proof of Enrollment file:', error);
    }
  };

  const canSubmit = () => {
    let rc = true;
    let requiredFields = [];

    // Student Requirements
    if (showAppForSenior()) {
      if (!form.btPrograms || form.btPrograms.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please select one or more Boys Town programs you have participated in.' }];
        rc = false;
      }

      if (form.extraCurricularActivites && form.extraCurricularActivites.length > 0) {
        for (let i = 0; i < form.extraCurricularActivites.length; i++) {
          const act = form.extraCurricularActivites[i];
          if (act.schoolYear && act.schoolYear.length > 0 && (!act.description || act.description.length === 0)) {
            requiredFields = [...requiredFields, { requirement: 'Ensure each Extra-Curricular Activity has both the Year and activity entered.' }];
            rc = false;
          }
          if (act.description && act.description.length > 0 && (!act.schoolYear || act.schoolYear.length === 0)) {
            requiredFields = [...requiredFields, { requirement: 'Ensure each Extra-Curricular Activity has both the year and Activity entered.' }];
            rc = false;
          }
        }
      }

      if (form.leadershipPositions && form.leadershipPositions.length > 0) {
        for (let i = 0; i < form.leadershipPositions.length; i++) {
          const act = form.leadershipPositions[i];
          if (
            act.schoolYear &&
            act.schoolYear.length > 0 &&
            (!act.description || act.description.length === 0 || !act.position || act.position.length === 0)
          ) {
            requiredFields = [...requiredFields, { requirement: 'Ensure each Leadership Position has the year, position and description entered.' }];
            rc = false;
          } else if (
            act.description &&
            act.description.length > 0 &&
            (!act.schoolYear || act.schoolYear.length === 0 || !act.position || act.position.length === 0)
          ) {
            requiredFields = [...requiredFields, { requirement: 'Ensure each Leadership Position has the year, position and description entered.' }];
            rc = false;
          } else if (
            act.position &&
            act.position.length > 0 &&
            (!act.description || act.description.length === 0 || !act.position || act.position.length === 0)
          ) {
            requiredFields = [...requiredFields, { requirement: 'Ensure each Leadership Position has the year, position and description entered.' }];
            rc = false;
          }
        }
      }

      if (!form.notCurrentlyEmployed) {
        if (!form.currentEmployer || form.currentEmployer.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter Current Employer.' }];
          rc = false;
        }
        if (!form.currentEmployerStartDate || form.currentEmployerStartDate.isValid === false) {
          requiredFields = [...requiredFields, { requirement: 'Please enter Current Employer Start Date.' }];
          rc = false;
        }
      }

      if (!form.address1 || form.address1.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a Street Address.' }];
        rc = false;
      }

      if (!form.city || form.city.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a City.' }];
        rc = false;
      }

      if (!form.state || form.state.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please select a State.' }];
        rc = false;
      }

      if (!form.phoneNumber || form.phoneNumber.length < 10) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a 10 digit phone number.' }];
        rc = false;
      }
      if (!form.zipCode || form.zipCode.length < 5) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a 5 digit zip code.' }];
        rc = false;
      }

      if (!form.postGradPlan || form.postGradPlan.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please choose a Post-Graduation Plan.' }];
        rc = false;
      }

      if (form.postGradPlan && form.postGradPlan === 'Trade/2-year College') {
        if (!form.accuplacerNotTaken && (!isNumeric(form.accuplacerScore) || form.accuplacerScore < 200 || form.accuplacerScore > 300)) {
          requiredFields = [...requiredFields, { requirement: 'ACCUPLACER must be a number between 200 and 300.' }];
          rc = false;
        }
        if (form.accuplacerNotTaken && (!form.accuplacerNotTakenReason || form.accuplacerNotTakenReason.length === 0)) {
          requiredFields = [...requiredFields, { requirement: 'Please explain why ACCUPLACER has not been taken.' }];
          rc = false;
        }
        if (!form.majorFieldOfWorkOrStudy || form.majorFieldOfWorkOrStudy.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate your Trade or Major.' }];
          rc = false;
        }
      }
      if (form.postGradPlan && form.postGradPlan === '4-year College') {
        if (!form.actScoreNotTaken && (!isNumeric(form.actScore) || form.actScore > 36)) {
          requiredFields = [...requiredFields, { requirement: 'ACT Score must be a number between 0 and 36.' }];
          rc = false;
        }
        if (form.actScoreNotTaken && (!form.actScoreNotTakenReason || form.actScoreNotTakenReason.length === 0)) {
          requiredFields = [...requiredFields, { requirement: 'Please explain why ACT has not been taken.' }];
          rc = false;
        }
        if (!form.actScoreNotTaken && (!form.actDate || form.actDate.isValid === false)) {
          requiredFields = [...requiredFields, { requirement: 'Please enter ACT Date.' }];
          rc = false;
        }
        if (!form.studentAidReportCompleted || form.studentAidReportCompleted.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate Student Aid Report completion.' }];
          rc = false;
        }
        if (form.studentAidReportCompleted && form.studentAidReportCompleted === 'yes' && !form.studentAidReport) {
          requiredFields = [...requiredFields, { requirement: 'Please upload a Student Aid Report.' }];
          rc = false;
        }
        if (!form.majorFieldOfWorkOrStudy || form.majorFieldOfWorkOrStudy.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate your Major.' }];
          rc = false;
        }
      }

      if (form.postGradPlan && (form.postGradPlan === 'Trade/2-year College' || form.postGradPlan === '4-year College')) {
        if (!form.schoolName || form.schoolName.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a school name.' }];
          rc = false;
        }
        if (!form.fafsaCompleted || form.fafsaCompleted.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate FAFSA completion.' }];
          rc = false;
        }
        if (form.fafsaCompleted === 'yes' && (!form.pellElligible || form.pellElligible.length === 0)) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate Pell Elligibility.' }];
          rc = false;
        }
        if (!form.housingPlan || form.housingPlan.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please select a housing plan.' }];
          rc = false;
        }
        if (form.housingPlan === 'Off-Campus' && (!form.offCampusHousingPlanExplain || form.offCampusHousingPlanExplain.length === 0)) {
          requiredFields = [...requiredFields, { requirement: 'Please explain your off-campus housing plan.' }];
          rc = false;
        }
        if (!form.holdPartTimeJob || form.holdPartTimeJob.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate if you plan to hold a part time job.' }];
          rc = false;
        }
        if (!form.otherScholarshipsApplied || !form.otherScholarshipsApplied.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate Other Scholarships you have applied for.' }];
          rc = false;
        }
        if (!form.otherScholarshipsAwarded || !form.otherScholarshipsAwarded.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate Other Scholarships you have been awarded.' }];
          rc = false;
        }
      }

      if (form.postGradPlan && form.postGradPlan === 'Military') {
        if (!form.asvabScoreNotTaken && (!isNumeric(form.asvabScore) || form.asvabScore > 99)) {
          requiredFields = [...requiredFields, { requirement: 'ASVAB Score must be a number between 0 and 99.' }];
          rc = false;
        }
        if (form.asvabScoreNotTaken && (!form.asvabScoreNotTakenReason || form.asvabScoreNotTakenReason.length === 0)) {
          requiredFields = [...requiredFields, { requirement: 'Please explain why ASVAB has not been taken.' }];
          rc = false;
        }
        if (!form.elistmentCeremonyDate || form.elistmentCeremonyDate.isValid === false) {
          requiredFields = [...requiredFields, { requirement: 'Please enter Oath of Enlistment Date.' }];
          rc = false;
        }
      }

      if (form.postGradPlan && form.postGradPlan === 'Job Corps') {
        if (!form.careerPath || form.careerPath.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a career path.' }];
          rc = false;
        }
        if (!form.jobCorpsAppCompleted || form.jobCorpsAppCompleted.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate if Job Corps application has been completed.' }];
          rc = false;
        }
        if (!form.jobCorpsAdmissionDate || form.jobCorpsAdmissionDate.isValid === false) {
          requiredFields = [...requiredFields, { requirement: 'Please enter Job Corps date of admission.' }];
          rc = false;
        }
        if (!form.jobCorpsLocation || form.jobCorpsLocation.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter Job Corps Location/Site.' }];
          rc = false;
        }
        if (!form.majorFieldOfWorkOrStudy || form.majorFieldOfWorkOrStudy.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate your Training Program.' }];
          rc = false;
        }
      }

      if (form.postGradPlan && form.postGradPlan === 'Workforce') {
        if (form.notCurrentlyEmployed) {
          if (!form.potentialEmployers || form.potentialEmployers.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please enter Potential Employers.' }];
            rc = false;
          }
        }
        if (!form.jobType || form.jobType.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a Type of Job.' }];
          rc = false;
        }
        if (!form.housingPlans || form.housingPlans.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter Housing Plans.' }];
          rc = false;
        }
        if (!form.transportPlans || form.transportPlans.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter Transportation Plans.' }];
          rc = false;
        }
        if (!form.resume) {
          requiredFields = [...requiredFields, { requirement: 'Please upload a Resume.' }];
          rc = false;
        }
      }

      if (!form.finalPostGradPlan || form.finalPostGradPlan.length === 0) {
        requiredFields = [
          ...requiredFields,
          {
            requirement:
              'Please explain, in detail, my final post-graduation plan. Include details of what, when, where and how I am paying for my plan.',
          },
        ];
        rc = false;
      }

      if (!form.outstandingFinalPlans || form.outstandingFinalPlans.length === 0) {
        requiredFields = [...requiredFields, { requirement: "Please answer 'What needs to be done to finalize my plan'." }];
        rc = false;
      }

      if (!form.finalPlanChallenges || form.finalPlanChallenges.length === 0) {
        requiredFields = [...requiredFields, { requirement: "Please enter the 'Challenges/Barriers to my plan'." }];
        rc = false;
      }

      if (!form.summerLivingArrangements || form.summerLivingArrangements.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter your Summer Living Arrangements.' }];
        rc = false;
      }

      if (!form.summerEmploymentPlans || form.summerEmploymentPlans.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter your Summer Employment Plans.' }];
        rc = false;
      }

      if (!form.familyFinancialSupportHousing || form.familyFinancialSupportHousing.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate Family Financial Support - Housing.' }];
        rc = false;
      }
      if (!form.familyFinancialSupportMedicalInsurance || form.familyFinancialSupportMedicalInsurance.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate Family Financial Support - Medical Insurance.' }];
        rc = false;
      }
      if (!form.familyFinancialSupportHealthNeeds || form.familyFinancialSupportHealthNeeds.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate Family Financial Support - Health Needs.' }];
        rc = false;
      }
      if (!form.familyFinancialSupportHealthNeeds || form.familyFinancialSupportHealthNeeds.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate Family Financial Support - Employment.' }];
        rc = false;
      }
      if (!form.familyFinancialSupportHealthNeeds || form.familyFinancialSupportHealthNeeds.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate Family Financial Support - Tuition.' }];
        rc = false;
      }
      if (!form.familyFinancialSupportWorkforceSupplies || form.familyFinancialSupportWorkforceSupplies.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate Family Financial Support - Workforce Supplies.' }];
        rc = false;
      }
      if (!form.familyFinancialSupportTransportation || form.familyFinancialSupportTransportation.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate Family Financial Support - Transportation.' }];
        rc = false;
      }

      if (!form.estimatedExpenses || form.estimatedExpenses.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter the Estimated Expenses description.' }];
        rc = false;
      }

      // needs
      let needsSelected = 0;
      if (form.hasOwnProperty('HousingSupportNeedsChecked') && form.HousingSupportNeedsChecked === true) {
        needsSelected += 1;
        if (!form.HousingSupportNeedsDesc || form.HousingSupportNeedsDesc.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a description for Housing Support Needs.' }];
          rc = false;
        }
      }
      if (form.hasOwnProperty('MedicalInsuranceNeedsChecked') && form.MedicalInsuranceNeedsChecked) {
        needsSelected += 1;
        if (!form.MedicalInsuranceNeedsDesc || form.MedicalInsuranceNeedsDesc.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a description for Medical Insurance Needs.' }];
          rc = false;
        }
      }
      if (form.hasOwnProperty('HealthNeedsChecked') && form.HealthNeedsChecked) {
        needsSelected += 1;
        if (!form.HealthNeedsDesc || form.HealthNeedsDesc.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a description for Health Needs.' }];
          rc = false;
        }
      }
      if (form.hasOwnProperty('EmploymentNeedsChecked') && form.EmploymentNeedsChecked) {
        needsSelected += 1;
        if (!form.EmploymentNeedsDesc || form.EmploymentNeedsDesc.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a description for Employment Needs.' }];
          rc = false;
        }
      }
      if (form.hasOwnProperty('TuitionNeedsChecked') && form.TuitionNeedsChecked) {
        needsSelected += 1;
        if (!form.TuitionNeedsDesc || form.TuitionNeedsDesc.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a description for Tuition Needs.' }];
          rc = false;
        }
      }
      if (form.hasOwnProperty('WorkforceSuppliesNeedsChecked') && form.WorkforceSuppliesNeedsChecked) {
        needsSelected += 1;
        if (!form.WorkforceSuppliesNeedsDesc || form.WorkforceSuppliesNeedsDesc.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a description for Workforce Supplies Needs.' }];
          rc = false;
        }
      }
      if (form.hasOwnProperty('TransportationNeedsChecked') && form.TransportationNeedsChecked) {
        needsSelected += 1;
        if (!form.TransportationNeedsDesc || form.TransportationNeedsDesc.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a description for Transportation Needs.' }];
          rc = false;
        }
      }
      if (form.hasOwnProperty('OtherNeedsChecked') && form.OtherNeedsChecked) {
        needsSelected += 1;
        if (!form.OtherNeedsDesc || form.OtherNeedsDesc.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter a description for Other Needs.' }];
          rc = false;
        }
      }
      if (needsSelected === 0) {
        requiredFields = [...requiredFields, { requirement: "Please enter one or more 'Outstanding Scholarship/Post-Graduation Support Needs'." }];
        rc = false;
      }

      if ((!form.essayText || form.essayText.length === 0) && !form.essay) {
        requiredFields = [...requiredFields, { requirement: 'Please attach an essay doc or enter your essay in the space provided.' }];
        rc = false;
      }
    }

    // Alumni Requirements
    if (showAppForAlumni()) {
      if (
        senior &&
        (senior.alumni_address.length === 0 ||
          senior.alumni_city.length === 0 ||
          senior.alumni_state.length === 0 ||
          senior.alumni_zip.length === 0 ||
          senior.alumni_phone_1.length === 0)
      ) {
        requiredFields = [...requiredFields, { requirement: 'Please enter your complete contact information on the Personal Information page.' }];
        rc = false;
      }

      if (!form.alumniSchoolName || form.alumniSchoolName.length < 3) {
        requiredFields = [...requiredFields, { requirement: 'Please enter the name of the school.' }];
        rc = false;
      }
      if (!form.alumniSchoolWebsite || form.alumniSchoolWebsite.length < 3) {
        requiredFields = [...requiredFields, { requirement: 'Please enter the school website url.' }];
        rc = false;
      }

      if (!form.alumniSchoolAddress || form.alumniSchoolAddress.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a Street Address for the school.' }];
        rc = false;
      }
      if (!form.alumniSchoolCity || form.alumniSchoolCity.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a City for the school.' }];
        rc = false;
      }
      if (!form.alumniSchoolState || form.alumniSchoolState.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please select a State for the school.' }];
        rc = false;
      }
      if (!form.alumniSchoolZip || form.alumniSchoolZip.length < 5) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a zip/postal code for the school.' }];
        rc = false;
      }
      if (!form.alumniSchoolPhone || form.alumniSchoolPhone.length < 10) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a phone number for the school.' }];
        rc = false;
      }

      if (!form.alumniScholarshipMailingAddress || form.alumniScholarshipMailingAddress.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a Street Address for the school.' }];
        rc = false;
      }
      if (!form.alumniScholarshipMailingCity || form.alumniScholarshipMailingCity.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a City for the school.' }];
        rc = false;
      }
      if (!form.alumniScholarshipMailingState || form.alumniScholarshipMailingState.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please select a State for the school.' }];
        rc = false;
      }
      if (!form.alumniScholarshipMailingZip || form.alumniScholarshipMailingZip.length < 5) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a zip/postal code for the school.' }];
        rc = false;
      }

      if (!form.alumniAdvisorName || form.alumniAdvisorName.length < 3) {
        requiredFields = [
          ...requiredFields,
          { requirement: 'Please enter the name of a scholarship coordinator, financial aid officer, or advisor.' },
        ];
        rc = false;
      }
      if (!form.alumniAdvisorPhone || form.alumniAdvisorPhone.length < 10) {
        requiredFields = [
          ...requiredFields,
          { requirement: 'Please enter the phone number for a scholarship coordinator, financial aid officer, or advisor.' },
        ];
        rc = false;
      }
      if (!form.alumniAdvisorEmail || form.alumniAdvisorEmail.length < 10) {
        requiredFields = [
          ...requiredFields,
          { requirement: 'Please enter the email address for a scholarship coordinator, financial aid officer, or advisor.' },
        ];
        rc = false;
      }

      if (!form.alumniDegree || form.alumniDegree.length < 1) {
        requiredFields = [...requiredFields, { requirement: 'Please select the degree for which you are requesting help.' }];
        rc = false;
      }
      if (!form.alumniAreaOfStudy || form.alumniAreaOfStudy.length < 1) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate your planned area of study.' }];
        rc = false;
      }
      if (!form.alumniPlannedCompletionDate || form.alumniPlannedCompletionDate.length < 8) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate your planned completion date.' }];
        rc = false;
      }

      if (!form.actScore || form.actScore.length < 1) {
        requiredFields = [...requiredFields, { requirement: 'Please provide your latest ACT, SAT, or GPA.' }];
        rc = false;
      }

      if (
        form.alumniHistoryDiploma === false &&
        form.alumniHistoryTrade === false &&
        form.alumniHistory4Year === false &&
        form.alumniHistoryMilitary === false &&
        form.alumniHistoryJobCorps === false &&
        form.alumniHistoryNone === false
      ) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate your school/professional history. If none, check the "none" option.' }];
        rc = false;
      }

      if (!form.alumniActivitiesNone) {
        for (let i = 0; i < form.alumniExtraCurricularActivities.length; i++) {
          const act = form.alumniExtraCurricularActivities[i];
          if ((!act.schoolYear || act.schoolYear.length === 0) && (!act.description || act.description.length === 0)) {
            requiredFields = [
              ...requiredFields,
              {
                requirement: 'Either enter your extracurricular activities or indicate you do not have any.',
              },
            ];
            rc = false;
          } else if (!act.schoolYear || act.schoolYear.length === 0 || !act.description || act.description.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Ensure each scholarship listed has a year and description entered.' }];
            rc = false;
          }
        }
      }

      if (!form.alumniLeadershipPositionsNone) {
        for (let i = 0; i < form.alumniLeadershipPositions.length; i++) {
          const act = form.alumniLeadershipPositions[i];
          if ((!act.description || act.description.length === 0) && (!act.position || act.position.length === 0)) {
            requiredFields = [
              ...requiredFields,
              {
                requirement: 'Either enter your leadership positions or indicate you do not have any.',
              },
            ];
            rc = false;
          } else if (!act.description || act.description.length === 0 || !act.position || act.position.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Ensure each scholarship listed has a description and position entered.' }];
            rc = false;
          }
        }
      }

      if (!form.alumniOtherScholarshipsNone) {
        for (let i = 0; i < form.alumniOtherScholarships.length; i++) {
          const act = form.alumniOtherScholarships[i];
          if (
            (!act.scholarshipName || act.scholarshipName.length === 0) &&
            (!act.scholarshipName || act.scholarshipName.length === 0) &&
            (!act.scholarshipAmount || act.scholarshipAmount.length === 0 || act.scholarshipAmount === 0) &&
            (!act.institution || act.institution.length === 0)
          ) {
            requiredFields = [
              ...requiredFields,
              {
                requirement:
                  'Either enter other scholarships for which you have applied or indicate you have not applied for any other scholarships.',
              },
            ];
            rc = false;
          } else if (
            !act.scholarshipName ||
            act.scholarshipName.length === 0 ||
            !act.institution ||
            act.institution.length === 0 ||
            !act.scholarshipAmount ||
            act.scholarshipAmount.length === 0 ||
            act.scholarshipAmount === 0 ||
            !act.scholarshipStatus ||
            act.scholarshipStatus.length === 0
          ) {
            requiredFields = [
              ...requiredFields,
              { requirement: 'Ensure each scholarship listed has a scholarship name, institution, amount, and status entered.' },
            ];
            rc = false;
          }
        }
      }

      if (!form.alumniExpensesTuition || form.alumniExpensesTuition.length < 1) {
        requiredFields = [...requiredFields, { requirement: 'Please provide the estimated cost of tuition. Enter 0 if none.' }];
        rc = false;
      }
      if (!form.alumniExpensesBooks || form.alumniExpensesBooks.length < 1) {
        requiredFields = [...requiredFields, { requirement: 'Please provide the estimated cost of books. Enter 0 if none.' }];
        rc = false;
      }
      if (!form.alumniExpensesFees || form.alumniExpensesFees.length < 1) {
        requiredFields = [...requiredFields, { requirement: 'Please provide the estimated cost of other fees. Enter 0 if none.' }];
        rc = false;
      }
      if (!form.alumniExpensesOther || form.alumniExpensesOther.length < 1) {
        requiredFields = [...requiredFields, { requirement: 'Please provide the estimated cost of other expenses. Enter 0 if none.' }];
        rc = false;
      } else {
        if (!form.alumniExpensesOtherExplanation || form.alumniExpensesOtherExplanation.length < 1) {
          requiredFields = [...requiredFields, { requirement: 'Please provide an explanation of the other expenses.' }];
          rc = false;
        }
      }

      if (!form.alumniFafsaCompleted || form.alumniFafsaCompleted.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate whether you have completed your FAFSA.' }];
        rc = false;
      } else {
        if (form.alumniFafsaCompleted === 'yes' && (!form.alumniFAFSA || form.alumniFAFSA.length === 0)) {
          requiredFields = [...requiredFields, { requirement: 'Please upload a FAFSA/SAR file.' }];
          rc = false;
        }
      }

      if (form.alumniFafsaCompleted === 'yes') {
        if (!form.alumniPellEligible || form.alumniPellEligible.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate whether you are Pell eligible.' }];
          rc = false;
        }
      }

      if (!form.alumniTranscriptAvailable || form.alumniTranscriptAvailable.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate whether you have an official transcript available.' }];
        rc = false;
      } else {
        if (form.alumniTranscriptAvailable === 'yes' && (!form.alumniTranscript || form.alumniTranscript.length === 0)) {
          requiredFields = [...requiredFields, { requirement: 'Please upload an official transcript file.' }];
          rc = false;
        } else if (
          form.alumniTranscriptAvailable === 'no' &&
          (!form.alumniTransriptNotAvailableExplanation || form.alumniTransriptNotAvailableExplanation.length === 0)
        ) {
          requiredFields = [...requiredFields, { requirement: 'Please explain why  a transcript is not available.' }];
          rc = false;
        }
      }

      if (!form.alumniEnrollmentLetterAvailable || form.alumniEnrollmentLetterAvailable.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate whether you have proof of enrollment or acceptance letter available.' }];
        rc = false;
      } else {
        if (form.alumniEnrollmentLetterAvailable === 'yes' && (!form.alumniEnrollment || form.alumniEnrollment.length === 0)) {
          requiredFields = [...requiredFields, { requirement: 'Please upload a proof of enrollment/acceptance letter file.' }];
          rc = false;
        } else if (
          form.alumniEnrollmentLetterAvailable === 'no' &&
          (!form.alumniEnrollmentNotAvailableExplanation || form.alumniEnrollmentNotAvailableExplanation.length === 0)
        ) {
          requiredFields = [...requiredFields, { requirement: 'Please explain why proof of enrollment is not available.' }];
          rc = false;
        }
      }

      if ((!form.essayText || form.essayText.length === 0) && !form.essay) {
        requiredFields = [...requiredFields, { requirement: 'Please attach an essay doc or enter your essay in the space provided.' }];
        rc = false;
      }

      if (!form.alumniSignature || form.alumniSignature.length < 1) {
        requiredFields = [...requiredFields, { requirement: 'Please include a typed signature for your application.' }];
        rc = false;
      }
    }

    setRequiredFieldsIntro(
      'You have not completed all of the required information on the Scholarship Application. Please make these adjustments before submitting your application.'
    );
    setRequiredFields(requiredFields);
    return rc;
  };

  const canAdminSave = () => {
    let rc = true;
    let requiredFields = [];

    // if admin is returning the scholarship application, require a reason.
    if (status === 'returned') {
      if (!dispositionReason || dispositionReason.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter as reason for returning the scholarship application.' }];
        rc = false;
      }
    }

    if (ssmModelCategory.length > 0) {
      if (!isNumeric(ssmModelCategory)) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (1-8) for the SSM Model Category.' }];
        rc = false;
      } else if (parseInt(ssmModelCategory, 10) < 1 || parseInt(ssmModelCategory, 10) > 8) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (1-8) for the SSM Model Category.' }];
        rc = false;
      }
    }

    // if (planningMeeting1Score.length > 0) {
    //   if (!isNumeric(planningMeeting1Score)) {
    //     requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (0-100) for the 1st Planning Meeting Score.' }];
    //     rc = false;
    //   } else if (parseInt(planningMeeting1Score, 10) < 0 || parseInt(planningMeeting1Score, 10) > 100) {
    //     requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (0-100) for the 1st Planning Meeting Score.' }];
    //     rc = false;
    //   }
    // }

    // if (planningMeeting2Score.length > 0) {
    //   if (!isNumeric(planningMeeting2Score)) {
    //     requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (0-100) for the 2nd Planning Meeting Score.' }];
    //     rc = false;
    //   } else if (parseInt(planningMeeting2Score, 10) < 0 || parseInt(planningMeeting2Score, 10) > 100) {
    //     requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (0-100) for the 2nd Planning Meeting Score.' }];
    //     rc = false;
    //   }
    // }

    if (spiritualityScore.length > 0) {
      if (!isNumeric(spiritualityScore)) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (0-5) for the Spirituality Score.' }];
        rc = false;
      } else if (parseInt(spiritualityScore, 10) < 0 || parseInt(spiritualityScore, 10) > 5) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (0-5) for the Spirituality Score.' }];
        rc = false;
      }
    }

    // Scoring
    if (status === 'qualified' || status === 'qualifiedinitial' || status === 'qualifiedprogram' || status === 'awarded' || status === 'denied') {
      if (
        gradingFinancialNeed < 0 ||
        gradingEssayVoice < 0 ||
        gradingEssayInspiration < 0 ||
        gradingEssayLanguage < 0 ||
        gradingEssayCompleted < 0 ||
        gradingCitizenshipLetters < 0 ||
        gradingCitizenshipActivities < 0 ||
        gradingCitizenshipRoleModel < 0
      ) {
        requiredFields = [
          ...requiredFields,
          { requirement: 'Please score each item in the Scoring section of the Grading the Application section.' },
        ];
        rc = false;
      }
    }

    // if (applicationScore.length > 0) {
    //   if (!isNumeric(applicationScore)) {
    //     requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (0-9) for the Application Score.' }];
    //     rc = false;
    //   } else if (parseInt(applicationScore, 10) < 0 || parseInt(applicationScore, 10) > 9) {
    //     requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (0-9) for the Application Score.' }];
    //     rc = false;
    //   }
    // }

    if (essayScore.length > 0) {
      if (!isNumeric(essayScore)) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (0-12) for the Essay Score.' }];
        rc = false;
      } else if (parseInt(essayScore, 10) < 0 || parseInt(essayScore, 10) > 12) {
        requiredFields = [...requiredFields, { requirement: 'Please enter a valid number (0-12) for the Essay Score.' }];
        rc = false;
      }
    }

    setRequiredFieldsIntro(
      'You have not completed all of the required information for reviewing the scholarship application. Please make these adjustments before saving.'
    );
    setRequiredFields(requiredFields);
    return rc;
  };

  const handleRequiredInfoClose = () => {
    setShowRequiredFieldsDialog(false);
  };

  const sendApplicationReturnedEmail = async (id) => {
    try {
      store.setLoading(true);
      store.setSearching(true);
      await callApi(auth, 'scholarshipreturnemail', 'POST', { student_id: id });
      store.setSearching(false);
      store.setLoading(false);
    } catch (error) {
      logException(error);
      store.setSearching(false);
      store.setLoading(false);
      console.log('Error sending scholarship returned email:', error);
    }
  };

  const displayStatus = (status) => {
    if (status === 'qualified' || status === 'qualifiedinitial' || status === 'qualifiedprogram') {
      return 'Under Review';
    }
    if (status === 'awarded') {
      return 'Processed';
    }
    if (status === 'denied') {
      return 'Processed';
    }
    return status;
  };

  const handleSaveApplication = async (isSubmit, doClose) => {
    try {
      if ((auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) && !canAdminSave()) {
        setShowRequiredFieldsDialog(true);
        return;
      }
      if (isSubmit && !canSubmit()) {
        setShowRequiredFieldsDialog(true);
        return;
      }

      store.setLoading(false);
      store.setSearching(true);

      // Send email
      if (!isSubmit && originalStatus !== 'returned' && status === 'returned') {
        await sendApplicationReturnedEmail(store.senior.id);
      }

      const item = {
        id: store.scholarshipAppId,
        userId: store.senior.userId,
        application: form,
        status: isSubmit ? 'submitted' : status,
        submitted_date: isSubmit ? new Date() : store.scholarshipApp.submitted_date,
        disposition_reason: isSubmit ? '' : dispositionReason,
        youth_strengths: youthStrengths,
        ssm_model_category: ssmModelCategory,
        application_score: applicationScore,
        essay_score: essayScore,
        spirituality_score: spiritualityScore,
        pell_amount: pellAmount,
        planning_meeting_1_score: planningMeeting1Score,
        planning_meeting_2_score: planningMeeting2Score,
        gpa_score: gradingGPA,
        financial_need_score: gradingFinancialNeed,
        length_of_stay_score: gradingLengthOfStay,
        essay_voice_score: gradingEssayVoice,
        essay_inspiration_score: gradingEssayInspiration,
        essay_word_score: gradingEssayLanguage,
        essay_completed_score: gradingEssayCompleted,
        citizenship_letters_score: gradingCitizenshipLetters,
        citizenship_activities_score: gradingCitizenshipActivities,
        citizenship_rolemodel_score: gradingCitizenshipRoleModel,
        status_date: new Date(),
        applicant_type: showAlumniAppForAdmin() || showAppForAlumni() ? 'alumni' : 'senior',
        status_by: isSubmit ? auth.user.result.first_name + ' ' + auth.user.result.last_name : store.scholarshipApp.status_by,
      };
      const app = await callApi(auth, 'scholarship', 'PUT', { item });

      setSavedFormJson(JSON.stringify(form));

      if (app.result) {
        store.setScholarshipApp(app.result);
        store.setScholarshipAppId(app.result.id);
        let tempApp = store.scholarshipList.find((s) => s.id === app.result.id);
        if (tempApp) {
          tempApp.status = app.result.status;
        }
      }

      store.setSearching(false);
      store.setLoading(false);
      if (doClose) handleSaveReturn();
    } catch (error) {
      logException(error);
      store.setSearching(false);
      store.setLoading(false);
      console.log('Error saving updated scholarship application:', error);
    }
  };

  const showUploadFAFSA = () => {
    if (showAlumniAppForAdmin() || showAppForAlumni()) {
      if (form.alumniFAFSA && form.alumniFAFSA.length > 0) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const showUploadTranscript = () => {
    if (showAlumniAppForAdmin() || showAppForAlumni()) {
      if (form.alumniTranscript && form.alumniTranscript.length > 0) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const showUploadEnrollment = () => {
    if (showAlumniAppForAdmin() || showAppForAlumni()) {
      if (form.alumniEnrollment && form.alumniEnrollment.length > 0) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const showDisposition = () => {
    if (showSeniorAppForAdmin() || showAlumniAppForAdmin()) {
      return true;
    }
    return false;
  };

  const showSaveButton = () => {
    if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
      // Status Values: submitted, qualifiedinitial, qualifiedprogram, awarded, denied, returned
      return true;
    } else if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
      // Status Values: submitted, qualifiedinitial, qualifiedprogram, awarded, denied, returned
      if (status === 'created' || status === 'returned') {
        return true;
      }
    }
    return false;
  };

  const showSeniorAppForAdmin = () => {
    if (store.scholarshipApp) {
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        if (store.scholarshipApp && store.scholarshipApp.applicant_type && store.scholarshipApp.applicant_type.length > 0) {
          if (store.scholarshipApp.applicant_type === 'senior') {
            return true;
          }
        } else if (store.alumni) {
          return true;
        }
        return false;
      }
    }
    return false;
  };

  const formatPhoneNumber = (str) => {
    if (!str || str.length < 1) return '';
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };

  const showAlumniAppForAdmin = () => {
    if (store.scholarshipApp) {
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        if (store.scholarshipApp && store.scholarshipApp.applicant_type && store.scholarshipApp.applicant_type.length > 0) {
          if (store.scholarshipApp.applicant_type === 'alumni') {
            return true;
          }
        } else if (store.alumni) {
          return true;
        }
        return false;
      }
    }
    return false;
  };

  const showAppForSenior = () => {
    if (auth.roles.includes('STUDENT')) {
      return true;
    }
    return false;
  };

  const showAppForAlumni = () => {
    if (auth.roles.includes('ALUMNI')) {
      return true;
    }
    return false;
  };

  /**SCHOLARSHIP AWARDS ********************************************************************************************/
  const fetchAllScholarshipAwards = async () => {
    try {
      const list = await callApi(auth, `awards`, 'GET');
      store.setAllAwards(list.result);
    } catch (error) {
      logException(error);
      console.log('Error fetching list of all scholarship awards:', error);
    }
  };

  const updateAwardScholarship = (rec, scholarship_id) => {
    const selectedName = getScholarshipNameFromId(scholarship_id, store.scholarships);

    // if an existing award is being updated, update corresponding store.appAwards record with new scholarship_id.
    if (rec.id > 0) {
      const ind = store.appAwards.findIndex((obj) => obj.id === rec.id);

      if (ind > -1) {
        // update existing record with new scholarship_id and clear out name (it will be updated on save)
        rec.scholarship_id = scholarship_id;
        rec.name = selectedName;
        store.appAwards[ind] = rec;
      }
    } else {
      // if a new award is being added, update the store.appAwards record using the tempid
      const ind = store.appAwards.findIndex((obj) => obj.tempid === rec.tempid && obj.scholarship_application_id === rec.scholarship_application_id);
      if (ind > -1) {
        rec.scholarship_id = scholarship_id;
        rec.name = selectedName;
        store.appAwards[ind] = rec;
      }
    }
  };

  const updateAwardStatus = (rec, status) => {
    // if an is being updated, update the store.appAwards record using the id
    if (rec.id > 0) {
      const ind = store.appAwards.findIndex((obj) => obj.id === rec.id);
      if (ind > -1) {
        rec.status = status;
        if (status === 'awarded' || status === 'denied') {
          rec.awarded_on = new Date();
        }
        store.appAwards[ind] = rec;
      }
    } else {
      // if a new award is being added, update the store.appAwards record using the tempid
      const ind = store.appAwards.findIndex((obj) => obj.tempid === rec.tempid && obj.scholarship_application_id === rec.scholarship_application_id);
      if (ind > -1) {
        rec.status = status;
        if (status === 'awarded' || status === 'denied') {
          rec.awarded_on = new Date();
        }
        store.appAwards[ind] = rec;
      }
    }
  };

  const updateAwardAmount = (rec, amount) => {
    // if an award is being updated, update the store.appAwards record using the id
    if (rec.id > 0) {
      const ind = store.appAwards.findIndex((obj) => obj.id === rec.id);
      if (ind > -1) {
        rec.amount = amount;
        store.appAwards[ind] = rec;
      }
    } else {
      // if a new award is being added, update the store.appAwards record using the tempid
      const ind = store.appAwards.findIndex((obj) => obj.tempid === rec.tempid && obj.scholarship_application_id === rec.scholarship_application_id);
      if (ind > -1) {
        rec.amount = amount;
        store.appAwards[ind] = rec;
      }
    }
  };

  const updateAwardNotes = (rec, notes) => {
    // if an award is being updated, update the store.appAwards record using the id
    if (rec.id > 0) {
      const ind = store.appAwards.findIndex((obj) => obj.id === rec.id);
      if (ind > -1) {
        rec.notes = notes;
        store.appAwards[ind] = rec;
      }
    } else {
      // if a new award is being added, update the store.appAwards record using the tempid
      const ind = store.appAwards.findIndex((obj) => obj.tempid === rec.tempid && obj.scholarship_application_id === rec.scholarship_application_id);
      if (ind > -1) {
        rec.notes = notes;
        store.appAwards[ind] = rec;
      }
    }
  };

  const updateAwardedOn = (rec, awardedDate) => {
    // if an award is being updated, update the store.appAwards record using the id
    if (rec.id > 0) {
      const ind = store.appAwards.findIndex((obj) => obj.id === rec.id);
      if (ind > -1) {
        rec.awarded_on = awardedDate;
        store.appAwards[ind] = rec;
      }
    } else {
      // if a new award is being added, update the store.appAwards record using the tempid
      const ind = store.appAwards.findIndex((obj) => obj.tempid === rec.tempid && obj.scholarship_application_id === rec.scholarship_application_id);
      if (ind > -1) {
        rec.awarded_on = awardedDate;
        store.appAwards[ind] = rec;
      }
    }
  };

  const deleteAward = async (record) => {
    try {
      store.setSearching(true);
      store.setLoading(true);

      if (record.id && record.id > 0) {
        await callApi(auth, 'awards/' + record.id, 'DELETE');

        appInsights.trackTrace({ message: `Scholarship award ${record.id} deleted by ${auth.user.result.email}` });
        const auditObj = {
          audit_ts: new Date(),
          user_id: auth.user.result.id,
          username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
          audit_type: 'scholarship award',
          action: 'deleted',
          value: 'Award ' + JSON.stringify(record) + ' deleted.',
        };
        logAudit(auth, auditObj);

        // Remove award from all awards lists.
        const awards = store.appAwards;
        const idx = awards.findIndex((s) => s.id === record.id);
        if (idx > -1) {
          awards.splice(idx, 1);
          store.setAppAwards(awards);
        }
        const allAwards = store.allAwards;
        const idx3 = allAwards.findIndex((s) => s.id === record.id);
        if (idx3 > -1) {
          allAwards.splice(idx3, 1);
          store.setAllAwards(allAwards);
        }

        store.setSearching(false);
        store.setLoading(false);

        // updateAwardTotals();
        const totalResults = updateAwardTotals(store.appAwards);
        setAwardPendingTotal(totalResults.pendingTotal);
        setAwardAwardedTotal(totalResults.awardedTotal);
        setAwardTotal(totalResults.total);

        fetchAllScholarshipAwards(); // update totals for all
      } else {
        const awards = store.appAwards;
        const ind = awards.findIndex((s) => s.tempid === record.tempid);
        if (ind > -1) {
          awards.splice(ind, 1);
          store.setAppAwards(awards);
        }
      }
    } catch (error) {
      logException(error);
      console.error('Error deleting scholarship award:', error);
    }
  };

  const completeScholarshipApplication = () => {
    let awardedCount = 0;
    let deniedCount = 0;
    let otherCount = 0;
    let totalCount = 0;
    store.appAwards.forEach((award, i) => {
      totalCount += 1;
      if (award.status === 'awarded') {
        awardedCount += 1;
      } else if (award.status === 'denied') {
        deniedCount += 1;
      } else {
        otherCount += 1;
      }
    });
    if (otherCount === 0) {
      if (deniedCount === totalCount) {
        handleSaveApplication(false, true);
      } else if (awardedCount > 0) {
        handleSaveApplication(false, true);
      }
    }

    setShowCompleteDialog(false);
    setShowAwardsDialog(false);
  };

  const handleOpenAwardsDialog = async (event, record, id, type) => {
    event.preventDefault();
    event.stopPropagation();
    // setAppClicked(record);
    setOriginalStatus(record.status);
    fetchScholarshipAwards(id);

    store.setScholarshipAppId(id);
    if (type === 'alumni') {
      store.setAlumni(true);
    } else {
      store.setAlumni(false);
    }

    try {
      store.setLoading(true);
      store.setSearching(true);
      fetchPlanningForm1(store.senior.id);
      fetchPlanningForm2(store.senior.id);
      setShowAwardsDialog(true);

      const totalResults = updateAwardTotals(store.appAwards);
      setAwardPendingTotal(totalResults.pendingTotal);
      setAwardAwardedTotal(totalResults.awardedTotal);
      setAwardTotal(totalResults.total);

      setTimeout(() => {
        setShowAwardsDialog(true);
      }, 1000);

      store.setSearching(false);
      store.setLoading(false);
    } catch (error) {
      logException(error);
      store.setSearching(false);
      store.setLoading(false);
      console.log('Error fetching studentplan for scholarship app:', error);
    }
  };

  const closeAwardsDialog = () => {
    setShowAwardsDialog(false);
  };

  const closeCompleteDialog = () => {
    setShowCompleteDialog(false);
  };

  const fetchScholarshipAwards = async (id) => {
    try {
      const list = await callApi(auth, `awards/app?scholarship_application_id=${id}`, 'GET');
      store.setAppAwards(list.result);
    } catch (error) {
      logException(error);
      console.log('Error fetching list of scholarship awards by application:', error);
    }
  };

  const fetchPlanningForm1 = async (userId) => {
    try {
      const pf1 = await callApi(auth, 'planningform1/' + userId, 'GET');
      const score = pf1.result;
      setSeniorPlanning1(score);
      store.setPlanningForm1Data(score);
    } catch (error) {
      logException(error);
      console.log('Error fetching planning form 1 data:', error);
    }
  };

  const fetchPlanningForm2 = async (userId) => {
    try {
      const pf2 = await callApi(auth, 'planningform2/' + userId, 'GET');
      const score = pf2.result;
      setSeniorPlanning2(score);
      store.setPlanningForm2Data(score);
    } catch (error) {
      logException(error);
      console.log('Error fetching planning form 2 data:', error);
    }
  };

  const updateAward = async (award) => {
    try {
      store.setLoading(false);
      store.setSearching(true);

      let scholarshipName = '';
      let version = 0;
      const ind = store.scholarships.findIndex((obj) => obj.id === award.scholarship_id);

      if (ind > -1) {
        scholarshipName = store.scholarships[ind].name;
        version = store.scholarships[ind].version;
      }

      let item = {
        id: award.id,
        scholarship_application_id: award.scholarship_application_id,
        userId: award.userId,
        scholarship_id: award.scholarship_id,
        version: version,
        name: scholarshipName,
        status: award.status,
        amount: award.amount,
        notes: award.notes,
        awarded_on: award.status === 'pending' ? null : award.awarded_on,
        updated_at: new Date(),
        updated_by: auth.user.result.id,
      };

      const res = await callApi(auth, 'awards', 'PUT', { item });

      // Updated award record in awards lists
      if (res.result) {
        let tempAward = store.appAwards.find((s) => s.id === res.id);
        if (tempAward) {
          tempAward = res.result;
        }
        let tempAllAward = store.allAwards.find((s) => s.id === res.id);
        if (tempAllAward) {
          tempAllAward = res.result;
        }
      }

      appInsights.trackTrace({ message: `Scholarship award updated by ${auth.user.result.email}` });
      const auditObj = {
        audit_ts: new Date(),
        user_id: auth.user.result.id,
        username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
        audit_type: 'scholarship award',
        action: 'updated',
        value: JSON.stringify(item).substring(0, 999),
      };
      logAudit(auth, auditObj);
      store.setSearching(false);
      store.setLoading(false);

      updateAwardTotals(); // Update totals for this application
      fetchAllScholarshipAwards(); // update totals for all
    } catch (error) {
      logException(error);
      store.setSearching(false);
      store.setLoading(false);
      console.log('Error writing updated scholarship award:', error);
    }
  };

  const createAward = async (award) => {
    try {
      store.setLoading(false);
      store.setSearching(true);

      let scholarshipName = '';
      let version = 0;
      const ind = store.scholarships.findIndex((obj) => obj.id === award.scholarship_id);
      if (ind > -1) {
        scholarshipName = store.scholarships[ind].name;
        version = store.scholarships[ind].version;
      }

      let item = {
        scholarship_application_id: award.scholarship_application_id,
        userId: store.senior.userId,
        scholarship_id: award.scholarship_id,
        version: version,
        name: scholarshipName,
        status: award.status || 'pending',
        amount: award.amount,
        notes: award.notes,
        awarded_on: award.awarded_on ? award.awarded_on : award.status === 'awarded' ? new Date() : null,
        'updated_at': new Date(),
        'updated_by': auth.user.result.id,
      };

      const app = await callApi(auth, 'awards', 'POST', { item });

      // Updated award record in awards lists
      store.appAwards.push(app.result);
      store.allAwards.push(app.result);

      appInsights.trackTrace({ message: `Scholarship award created by ${auth.user.result.email}` });
      const auditObj = {
        audit_ts: new Date(),
        user_id: auth.user.result.id,
        username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
        audit_type: 'scholarship award',
        action: 'created',
        value: JSON.stringify(item).substring(0, 999),
      };
      logAudit(auth, auditObj);
      store.setSearching(false);
      store.setLoading(false);

      updateAwardTotals(); // Update totals for this application
      fetchAllScholarshipAwards(); // update totals for all
    } catch (error) {
      logException(error);
      store.setSearching(false);
      store.setLoading(false);
      console.error('Error creating scholarship award:', error);
    }
  };

  /*****************************************************************************************************************/

  if (!senior) {
    return <div />;
  }

  return (
    <React.Fragment>
      {store.appCreated ? (
        <Grid container direction="row" className={classes.sectionSpacer}>
          <Grid item xs={false} sm={false} md={1} lg={2} align="left" />
          <Grid item xs={12} sm={12} md={10} lg={8} align="left">
            <section align="left" className={classes.sectionTitle}>
              <ol>
                <li>
                  After you create your Scholarship application, your responses will be automatically saved every five minutes. You can return to the
                  application to edit and save as many times as you wish.
                </li>
                <li>Once you are satisfied with your application, use the "Submit" button to send the application in for consideration.</li>
                <li>Once submitted, your application cannot be modified.</li>
              </ol>
            </section>
            <section align="center">
              <Button color="primary" variant="contained" onClick={(event) => handleUnderstand()} style={{ margin: '10px' }}>
                I understand
              </Button>
            </section>
          </Grid>
          <Grid item xs={false} sm={false} md={1} lg={2} align="left" />
        </Grid>
      ) : (
        <Grid item xs={12} align="left">
          {(showAppForSenior() || showAppForAlumni()) && (
            <Grid item xs={12} align="center" className={classes.textRowLabelSpacer}>
              {status === 'returned' ? (
                <aside className={classes.error}>
                  NOTE: This Scholarship application was returned for additional information. Please make the necessary changes and re-submit.
                </aside>
              ) : (
                <aside style={{ fontWeight: 600, color: status === 'returned' ? 'red' : '#000' }}>
                  Current Status: {titleize(displayStatus(status))}
                </aside>
              )}
            </Grid>
          )}

          {status === 'returned' && dispositionReason && dispositionReason.length > 0 && (
            <Grid item xs={12} className={classes.dispositionNote}>
              <React.Fragment>
                <summary style={{ fontWeight: 600 }}>Notes Regarding Status</summary>
                <summary>{dispositionReason}</summary>
              </React.Fragment>
            </Grid>
          )}

          <Instructions classes={classes} />
          {(showAlumniAppForAdmin() || showAppForAlumni()) && (
            <React.Fragment>
              <AlumniAppSection
                form={form}
                setForm={setForm}
                senior={senior}
                classes={classes}
                disabled={alumnidisabled}
                formatPhoneNumber={formatPhoneNumber}
              />
              <AlumniSchoolProgram form={form} setForm={setForm} senior={senior} classes={classes} disabled={alumnidisabled} />
              <AlumniFinancialInfo form={form} setForm={setForm} senior={senior} classes={classes} disabled={alumnidisabled} />
            </React.Fragment>
          )}
          {(showSeniorAppForAdmin() || showAppForSenior()) && (
            <AppSection form={form} setForm={setForm} senior={senior} classes={classes} disabled={disabled} roles={auth.roles} />
          )}
          {(showSeniorAppForAdmin() || showAppForSenior()) && (
            <React.Fragment>
              <Grid item xs={12} align="left">
                <Grid item xs={12}>
                  <section align="left" className={classes.sectionTitle}>
                    Extra-Curricular Activities
                  </section>
                </Grid>

                <Grid container direction="row" className={classes.unprintedContent}>
                  <Grid item xs={12}>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={6} sm={3} md={3} lg={3} className={classes.rowSpacer}>
                        <label>School Year</label>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.rowSpacer}>
                        <label>Activity</label>
                      </Grid>
                    </Grid>
                    <ExpandingList2
                      className={classes.unprintedContent}
                      form={form}
                      setForm={setForm}
                      listname="extraCurricularActivites"
                      rowsRequired={1}
                      disabled={disabled}
                      field1="schoolYear"
                      field1Cols={3}
                      field1MaxLength={100}
                      field2="description"
                      field2Cols={8}
                      field2MaxLength={255}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={12}>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={6} sm={3} md={3} lg={3} className={classes.rowSpacer}>
                        <label>School Year</label>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.rowSpacer}>
                        <label>Activity</label>
                      </Grid>
                    </Grid>
                    {form.extraCurricularActivites.map((e, i) => {
                      return (
                        <Grid container direction="row" className={classes.rowSpacer} key={i}>
                          <Grid item xs={6} sm={3} md={3} lg={3} className={classes.rowSpacer}>
                            {e.schoolYear}
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} className={classes.rowSpacer}>
                            {e.description}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} align="left">
                <Grid item xs={12}>
                  <section align="left" className={classes.sectionTitle}>
                    Leadership Positions
                  </section>
                </Grid>
                <Grid container direction="row" className={classes.unprintedContent}>
                  <Grid item xs={12}>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={2} sm={2} md={2} lg={2} className={classes.rowSpacer}>
                        <label>School Year</label>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3} className={classes.rowSpacer}>
                        <label>Position</label>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.rowSpacer}>
                        <label>Description</label>
                      </Grid>
                    </Grid>
                    <ExpandingList3
                      className={classes.unprintedContent}
                      form={form}
                      setForm={setForm}
                      listname="leadershipPositions"
                      rowsRequired={1}
                      disabled={disabled}
                      field1="schoolYear"
                      field1Cols={2}
                      field1MaxLength={20}
                      field2="position"
                      field2Cols={3}
                      field2MaxLength={100}
                      field3="description"
                      field3Cols={6}
                      field3MaxLength={255}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" className={classes.printedContent}>
                  <Grid item xs={12}>
                    <Grid container direction="row" className={classes.rowSpacer}>
                      <Grid item xs={4} sm={3} md={3} lg={3} className={classes.rowSpacer}>
                        <label>School Year</label>
                      </Grid>
                      <Grid item xs={4} sm={3} md={3} lg={3} className={classes.rowSpacer}>
                        <label>Position</label>
                      </Grid>
                      <Grid item xs={4} sm={6} md={6} lg={6} className={classes.rowSpacer}>
                        <label>Description</label>
                      </Grid>
                    </Grid>
                    {form.leadershipPositions.map((e, i) => {
                      return (
                        <Grid container direction="row" className={classes.rowSpacer} key={i}>
                          <Grid item xs={4} sm={3} md={3} lg={3} className={classes.rowSpacer}>
                            {e.schoolYear}
                          </Grid>
                          <Grid item xs={4} sm={3} md={3} lg={3} className={classes.rowSpacer}>
                            {e.position}
                          </Grid>
                          <Grid item xs={4} sm={6} md={6} lg={6} className={classes.rowSpacer}>
                            {e.description}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          {(showSeniorAppForAdmin() || showAppForSenior()) && (
            <CurrentEmployment form={form} setForm={setForm} classes={classes} disabled={disabled} />
          )}
          {(showSeniorAppForAdmin() || showAppForSenior()) && <PostGradSection form={form} setForm={setForm} classes={classes} disabled={disabled} />}
          {(showSeniorAppForAdmin() || showAppForSenior()) && (
            <Grid item xs={12} align="left">
              <Grid item xs={12}>
                <section align="left" className={classes.sectionTitle}>
                  Summer Plans
                </section>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={12} className={classes.textRowLabelSpacer}>
                  <label>Living Arrangements</label>
                </Grid>
                <Grid item xs={12} className={[classes.rowSpacer, classes.unprintedContent].join(' ')}>
                  <FormControl fullWidth>
                    <TextField
                      id="summerLivingArrangements"
                      size="small"
                      hiddenLabel
                      placeholder="Describe my living arrangements/housing plans for the summer."
                      variant="outlined"
                      value={form.summerLivingArrangements}
                      onChange={(event) => setForm({ ...form, summerLivingArrangements: event.target.value })}
                      InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
                      disabled={disabled}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={[classes.rowSpacer, classes.printedContent].join(' ')}>
                  {form.summerLivingArrangements}
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={12} className={classes.textRowLabelSpacer}>
                  <label>Employment Plans</label>
                </Grid>
                <Grid item xs={12} className={[classes.rowSpacer, classes.unprintedContent].join(' ')}>
                  <FormControl fullWidth>
                    <TextField
                      id="summerEmploymentPlans"
                      size="small"
                      hiddenLabel
                      placeholder="Describe my employment plans for the summer."
                      variant="outlined"
                      value={form.summerEmploymentPlans}
                      onChange={(event) => setForm({ ...form, summerEmploymentPlans: event.target.value })}
                      InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
                      disabled={disabled}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={[classes.rowSpacer, classes.printedContent].join(' ')}>
                  {form.summerEmploymentPlans}
                </Grid>
              </Grid>
            </Grid>
          )}
          {(showSeniorAppForAdmin() || showAppForSenior()) && (
            <FamilyFinancialSupport form={form} setForm={setForm} classes={classes} disabled={disabled} />
          )}
          {(showSeniorAppForAdmin() || showAppForSenior()) && (
            <EstimatedExpenses form={form} setForm={setForm} classes={classes} disabled={disabled} />
          )}
          {(showSeniorAppForAdmin() || showAppForSenior()) && (
            <Grid item xs={12} align="left">
              <Grid item xs={12}>
                <section align="left" className={classes.sectionTitle}>
                  Outstanding Scholarship/Post-Graduation Support Needs
                </section>
              </Grid>

              <Needs form={form} setForm={setForm} classes={classes} disabled={disabled} needsName="Housing Support" />
              <Needs form={form} setForm={setForm} classes={classes} disabled={disabled} needsName="Medical Insurance" />
              <Needs form={form} setForm={setForm} classes={classes} disabled={disabled} needsName="Health" />
              <Needs form={form} setForm={setForm} classes={classes} disabled={disabled} needsName="Employment" />
              <Needs form={form} setForm={setForm} classes={classes} disabled={disabled} needsName="Tuition" />
              <Needs form={form} setForm={setForm} classes={classes} disabled={disabled} needsName="Workforce Supplies" />
              <Needs form={form} setForm={setForm} classes={classes} disabled={disabled} needsName="Transportation" />
              <Needs form={form} setForm={setForm} classes={classes} disabled={disabled} needsName="Other" />
            </Grid>
          )}
          {(showSeniorAppForAdmin() || showAppForSenior()) && (
            <React.Fragment>
              <Grid item xs={12} align="left">
                <Grid item xs={12}>
                  <section align="left" className={classes.sectionTitle}>
                    Letters of Recommendation
                  </section>
                  <Box sx={{ width: '95%' }}>
                    <section>
                      REMINDER: Two recommendation letters are required as part of your application. To request recommendations, or view the status of
                      pending recommendation requests, please visit the Letters of Recommendation page.
                    </section>
                  </Box>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12} align="left" style={{ marginBottom: '20px' }}>
            <Grid item xs={12}>
              <section align="left" className={classes.sectionTitle}>
                Essay
              </section>
              <Box sx={{ width: '95%' }}>
                <section className={classes.unprintedContent}>
                  <label className={classes.instructions}>
                    Type a 1000-word essay entitled "The Bright Future Ahead of Me" in which you elaborate on the main reasons why you are applying
                    for a Boys Town scholarship and/or support. To facilitate the writing process, you could use the following instructions:
                  </label>
                  <br />
                  <label className={classes.instructions}>The essay should have four parts:</label>
                  <label className={classes.instructions}>
                    <ol>
                      <li>
                        Start by introducing yourself and briefly describe your post-graduation plans. You could use a summarized version of what you
                        already wrote as part of the application above.
                      </li>
                      <li>
                        Reflect on your experiences at Boys Town, answering questions such as: What is your greatest lesson(s) learned at Boys Town?
                        Who has been instrumental in teaching you how to be successful?
                      </li>
                      <li>
                        Explain the needs you have in order to achieve the plan from the introductory paragraph and address questions such as: What do
                        you anticipate your future temptations/struggles will be? How will you use what you have learned to overcome obstacles in
                        order to be successful? How are you stronger today than you were before?
                      </li>
                      <li>
                        Finally, close your essay expressing thanks for the opportunity to apply, describing what success means to you, and how the
                        Boys Town Scholarship/Support will help you be successful in your future life.
                      </li>
                    </ol>
                  </label>
                </section>

                <section className={classes.marginTop15}>
                  <Grid container direction="row">
                    <Grid item xs={12} className={classes.unprintedContent} style={{ padding: '5px 0 10px 0' }}>
                      <label className={classes.essayOptions}>
                        NOTE: You can either upload an essay (Word document, text file, or PDF) or type your essay in the space provided.
                      </label>
                    </Grid>
                  </Grid>
                  <React.Fragment>
                    <Grid container direction="row">
                      <Grid item xs={12} className={classes.unprintedContent}>
                        {form.essay && (
                          <summary className={classes.essayOptions} style={{ marginBottom: 10 }}>
                            File Uploaded:
                            <Chip
                              style={{ margin: 5 }}
                              label={form.essay && form.essay.filename}
                              onDelete={() => removeEssay()}
                              color="primary"
                              onClick={() => downloadFile(form.essay)}
                            />
                          </summary>
                        )}
                        {!form.essay && (showAppForSenior() || showAppForAlumni()) && (
                          <div style={{ marginBottom: '10px' }}>
                            <Button
                              variant="outlined"
                              component="label"
                              color="primary"
                              disabled={!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI')}
                            >
                              Upload Essay
                              <input type="file" onChange={(e) => confirmFileUpload(e.target.files)} hidden />
                            </Button>
                            {form.essay && form.essay.name}
                            <br />
                            <aside className={classes.error}>{uploadError}</aside>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12} className={classes.printedContent}>
                        {form.essay && <span>{form.essay.filename} has been uploaded</span>}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                  {!form.essay && (
                    <React.Fragment>
                      <Grid container direction="row">
                        <Grid item xs={12} className={classes.textArea25RowSpacer + ' ' + classes.unprintedContent}>
                          <FormControl fullWidth>
                            <TextareaAutosize
                              aria-label="scholarship application essay"
                              minRows={30}
                              maxRows={50}
                              maxLength={7500}
                              value={form.essayText}
                              onChange={(event) => setForm({ ...form, essayText: event.target.value })}
                              disabled={!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI')}
                              className={classes.textAreaFont}
                              style={{ color: disabled && 'rgba(0, 0, 0, 0.4)' }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <p className={classes.printedContent} style={{ whiteSpace: 'pre-wrap', border: '1px inset #000', padding: '3px' }}>
                        {form.essayText || null}
                      </p>
                    </React.Fragment>
                  )}
                </section>
              </Box>
            </Grid>
          </Grid>
          {(showAlumniAppForAdmin() || showAppForAlumni()) && (
            <React.Fragment>
              <Grid container direction="row">
                <Grid item xs={12} style={{ margin: '30px 0 10px 0' }}>
                  <section align="left" className={classes.sectionTitle}>
                    Attachments &amp; Signatures
                  </section>
                </Grid>
              </Grid>

              {/* FAFSA/SAR */}
              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} md={6} lg={3} className={classes.radioGroupLabel}>
                  <label>FAFSA Completed?</label>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth>
                    <RadioGroup
                      row
                      aria-label="FAFSA completed"
                      name="fafsa-completed"
                      value={form.alumniFafsaCompleted}
                      onChange={(event) => setForm({ ...form, alumniFafsaCompleted: event.target.value })}
                      disabled={alumnidisabled}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="default" disabled={alumnidisabled} />}
                        label={<span className={classes.radioLabel}>Yes</span>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="default" disabled={alumnidisabled} />}
                        label={<span className={classes.radioLabel}>No</span>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={12} className={classes.textRowLabelSpacer}>
                  <label>FAFSA Completed?</label> {form.alumniFafsaCompleted}
                </Grid>
                <Grid item xs={12} className={classes.printedContent}>
                  {form.alumniFAFSA &&
                    form.alumniFAFSA.map((file, i) => {
                      return <div key={i}>FAFSA/SAR file '{file.filename}' has been uploaded.</div>;
                    })}
                </Grid>
              </Grid>

              {form.alumniFafsaCompleted && form.alumniFafsaCompleted === 'yes' && (
                <React.Fragment>
                  <Grid container direction="row" className={classes.unprintedContent}>
                    <Grid item xs={12} md={6} lg={3} className={classes.radioGroupLabel}>
                      <label>Pell Eligible?</label>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FormControl fullWidth>
                        <RadioGroup
                          row
                          aria-label="Pell eligible"
                          name="pell-eligible"
                          value={form.alumniPellEligible}
                          onChange={(event) => setForm({ ...form, alumniPellEligible: event.target.value })}
                          disabled={alumnidisabled}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio color="default" disabled={alumnidisabled} />}
                            label={<span className={classes.radioLabel}>Yes</span>}
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio color="default" disabled={alumnidisabled} />}
                            label={<span className={classes.radioLabel}>No</span>}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className={classes.printedContent}>
                    <Grid item xs={12} className={classes.textRowLabelSpacer}>
                      <label>Pell Eligible?</label> {form.alumniPellEligible}
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}

              <Grid item xs={12} align="left" style={{ marginBottom: '20px' }}>
                <Grid container direction="row">
                  <Grid item xs={12} className={classes.unprintedContent}>
                    {showUploadFAFSA() && (
                      <div style={{ marginBottom: 10 }}>
                        <Button variant="contained" component="label" color="primary">
                          Upload FAFSA/SAR
                          <input
                            type="file"
                            hidden
                            onChange={(e) => {
                              confirmFAFSAUpload(e.target.files).then(() => (e.target.value = ''));
                            }}
                          />
                        </Button>
                        <br />
                        {form.alumniFAFSA && form.alumniFAFSA.length > 0 && form.alumniFAFSA[0].name}
                        <aside className={classes.error}>{alumniFAFSAError}</aside>
                      </div>
                    )}
                    {form.alumniFAFSA && form.alumniFAFSA.length > 0 && (
                      <summary className={classes.essayOptions} style={{ marginBottom: 10 }}>
                        FAFSA/SAR File(s) Uploaded:
                        {form.alumniFAFSA &&
                          form.alumniFAFSA.map((file, i) => {
                            return (
                              <span style={{ marginBottom: 10 }} key={i}>
                                <Chip
                                  style={{ margin: 5 }}
                                  label={file && file.filename}
                                  onDelete={() => removeFAFSAFile(file.filename)}
                                  color="primary"
                                  onClick={() => downloadFile(file)}
                                />
                              </span>
                            );
                          })}
                      </summary>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Official Transcript */}
              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} md={6} lg={3} className={classes.radioGroupLabel}>
                  <label>Official Transcript Available?</label>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth>
                    <RadioGroup
                      row
                      aria-label="Transcript available"
                      name="transcript-available"
                      value={form.alumniTranscriptAvailable}
                      onChange={(event) => setForm({ ...form, alumniTranscriptAvailable: event.target.value })}
                      disabled={alumnidisabled}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="default" disabled={alumnidisabled} />}
                        label={<span className={classes.radioLabel}>Yes</span>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="default" disabled={alumnidisabled} />}
                        label={<span className={classes.radioLabel}>No</span>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              {form.alumniTranscriptAvailable && form.alumniTranscriptAvailable === 'no' && (
                <Grid container direction="row" className={classes.unprintedContent}>
                  <Grid item xs={12} md={6} lg={3} className={classes.textRowSpacer}>
                    <label>Why is a transcript not available?</label>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="transcript-not-available-explanation"
                        size="small"
                        placeholder="Explain why transcript is not available."
                        variant="outlined"
                        value={form.alumniTransriptNotAvailableExplanation}
                        onChange={(event) => setForm({ ...form, alumniTransriptNotAvailableExplanation: event.target.value })}
                        InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                        disabled={alumnidisabled}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} align="left" style={{ margin: '10px 0 20px 0' }}>
                <Grid container direction="row">
                  <Grid item xs={12} className={classes.unprintedContent}>
                    {showUploadTranscript() && (
                      <div style={{ marginBottom: 10 }}>
                        <Button variant="contained" component="label" color="primary">
                          Upload Official Transcript
                          <input
                            type="file"
                            hidden
                            onChange={(e) => {
                              confirmTranscriptUpload(e.target.files).then(() => (e.target.value = ''));
                            }}
                          />
                        </Button>
                        <br />
                        {form.alumniTranscript && form.alumniTranscript.length > 0 && form.alumniTranscript[0].name}
                        <aside className={classes.error}>{alumniTranscriptError}</aside>
                      </div>
                    )}
                    {form.alumniTranscript && form.alumniTranscript.length > 0 && (
                      <summary className={classes.essayOptions} style={{ marginBottom: 10 }}>
                        Official Transcript File(s) Uploaded:
                        {form.alumniTranscript &&
                          form.alumniTranscript.map((file, i) => {
                            return (
                              <span style={{ marginBottom: 10 }} key={i}>
                                <Chip
                                  style={{ margin: 5 }}
                                  label={file && file.filename}
                                  onDelete={() => removeTranscriptFile(file.filename)}
                                  color="primary"
                                  onClick={() => downloadFile(file)}
                                />
                              </span>
                            );
                          })}
                      </summary>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={12} className={classes.textRowLabelSpacer}>
                  <label>Official Transcript Available?</label> {form.alumniTranscriptAvailable}
                </Grid>
                {form.alumniTranscriptAvailable === 'no' && (
                  <Grid item xs={12} className={classes.textRowLabelSpacer} style={{ marginBottom: 10 }}>
                    <label>Reason Transcript is not Available</label> {form.alumniTransriptNotAvailableExplanation}
                  </Grid>
                )}
                <Grid item xs={12} className={classes.printedContent}>
                  {form.alumniTranscript &&
                    form.alumniTranscript.map((file, i) => {
                      return <div key={i}>Transcript file '{file.filename}' has been uploaded.</div>;
                    })}
                </Grid>
              </Grid>

              {/* Enrollment Letter */}
              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} md={6} lg={3} className={classes.radioGroupLabel}>
                  <label>Proof of Enrollment / Acceptance Letter Available?</label>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth>
                    <RadioGroup
                      row
                      aria-label="Enrollment Letter available"
                      name="enrollment-letter-available"
                      value={form.alumniEnrollmentLetterAvailable}
                      onChange={(event) => setForm({ ...form, alumniEnrollmentLetterAvailable: event.target.value })}
                      disabled={alumnidisabled}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="default" disabled={alumnidisabled} />}
                        label={<span className={classes.radioLabel}>Yes</span>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="default" disabled={alumnidisabled} />}
                        label={<span className={classes.radioLabel}>No</span>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {form.alumniEnrollmentLetterAvailable && form.alumniEnrollmentLetterAvailable === 'no' && (
                <React.Fragment>
                  <Grid item xs={12} md={6} lg={3} className={classes.textRowSpacer + ' ' + classes.unprintedContent}>
                    <label>Why is proof of enrollment not available?</label>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="transcript-not-available-explanation"
                        size="small"
                        placeholder="Explain why proof of enrollment is not available."
                        variant="outlined"
                        value={form.alumniEnrollmentNotAvailableExplanation}
                        onChange={(event) => setForm({ ...form, alumniEnrollmentNotAvailableExplanation: event.target.value })}
                        InputProps={{ maxLength: 100, classes: { input: classes.textField } }}
                        disabled={alumnidisabled}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}

              <Grid item xs={12} align="left" style={{ margin: '10px 0 20px 0' }}>
                <Grid container direction="row">
                  <Grid item xs={12} className={classes.unprintedContent}>
                    {showUploadEnrollment() && (
                      <div style={{ marginBottom: 10 }}>
                        <Button variant="contained" component="label" color="primary">
                          Upload Enrollment Letter/Proof of Enrollment
                          <input
                            type="file"
                            hidden
                            onChange={(e) => {
                              confirmEnrollmentUpload(e.target.files).then(() => (e.target.value = ''));
                            }}
                          />
                        </Button>
                        <br />
                        {form.alumniTranscript && form.alumniTranscript.length > 0 && form.alumniTranscript[0].name}
                        <aside className={classes.error}>{alumniEnrollmentError}</aside>
                      </div>
                    )}
                    {form.alumniEnrollment && form.alumniEnrollment.length > 0 && (
                      <summary className={classes.essayOptions} style={{ marginBottom: 10 }}>
                        Proof of Enrollment File(s) Uploaded:
                        {form.alumniEnrollment &&
                          form.alumniEnrollment.map((file, i) => {
                            return (
                              <span style={{ marginBottom: 10 }} key={i}>
                                <Chip
                                  style={{ margin: 5 }}
                                  label={file && file.filename}
                                  onDelete={() => removeEnrollmentFile(file.filename)}
                                  color="primary"
                                  onClick={() => downloadFile(file)}
                                />
                              </span>
                            );
                          })}
                      </summary>
                    )}
                  </Grid>
                  <Grid item xs={12} className={classes.printedContent}></Grid>
                </Grid>
              </Grid>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={12} className={classes.textRowLabelSpacer}>
                  <label>Enrollment Letter / Proof of Enrollment Available?</label> {form.alumniEnrollmentLetterAvailable}
                </Grid>
                {form.alumniEnrollmentLetterAvailable === 'no' && (
                  <Grid item xs={12} className={classes.textRowLabelSpacer} style={{ marginBottom: 10 }}>
                    <label>Reason Proof of Enrollment is not Available</label> {form.alumniEnrollmentNotAvailableExplanation}
                  </Grid>
                )}
                <Grid item xs={12} className={classes.printedContent}>
                  {form.alumniEnrollment &&
                    form.alumniEnrollment.map((file, i) => {
                      return <div key={i}>Proof of Enrollment file '{file.filename}' has been uploaded.</div>;
                    })}
                </Grid>
              </Grid>

              {/* Alumni Letters of Recommendation */}
              <Grid item xs={12} align="left">
                <Grid item xs={12}>
                  <section align="left" className={classes.sectionTitle}>
                    Letter(s) of Recommendation
                  </section>
                  <Box sx={{ width: '95%', marginBottom: '15px' }}>
                    <section>
                      REMINDER: Two recommendation letters are required as part of your application. To request recommendations, or view the status of
                      pending recommendation requests, please visit the Letters of Recommendation page.
                    </section>
                  </Box>
                </Grid>
              </Grid>

              <Grid container direction="row" className={classes.unprintedContent}>
                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.textRowSpacer}>
                  <label>Typed Signature</label>
                  <FormControl fullWidth>
                    <TextField
                      id="signature"
                      size="small"
                      hiddenLabel
                      placeholder="Signature"
                      variant="outlined"
                      value={form.alumniSignature}
                      onChange={(event) => setForm({ ...form, alumniSignature: event.target.value })}
                      disabled={alumnidisabled}
                      InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={false} sm={6} md={8} lg={9} className={classes.textRowSpacer}></Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} className={classes.textRowSpacer}>
                  <label>
                    By typing my name in the space above I affirm that I have read and reviewed this application and certify that all the information
                    provided is correct and complete. I understand that this application and any supporting documentation are subject to verification
                    and will be reviewed by the BTNAASF Board of Directors.
                  </label>
                </Grid>
                <Grid item xs={false} sm={false} md={3} lg={3} className={classes.textRowSpacer}></Grid>
              </Grid>
              <Grid container direction="row" className={classes.printedContent}>
                <Grid item xs={4}>
                  <label className={classes.alignPrintedContent}>Signature</label>
                </Grid>
                <Grid item xs={8}>
                  <summary className={classes.alignPrintedContent}>{form.alumniSignature || null}</summary>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          {showDisposition() && (
            <React.Fragment>
              <Grid
                container
                direction="row"
                className={classes.unprintedContent}
                style={{ marginTop: 20, padding: 20, border: '1px solid darkgreen', background: '#dcf5dc' }}
              >
                <Grid item xs={6} sm={8} md={9} style={{ marginTop: 8, marginBottom: 10 }}>
                  <label style={{ fontWeight: 600, fontSize: '1.15rem' }}>Successful Futures Administration Only</label>
                </Grid>
                <Grid item xs={6} sm={4} md={3} align="right" style={{ marginTop: 5, marginBottom: 10 }}>
                  {showAwardsButton && (
                    <Button
                      color="primary"
                      variant="outlined"
                      className={classes.approvalLink}
                      aria-describedby="approve request"
                      onClick={(ev) => handleOpenAwardsDialog(ev, application, store.scholarshipAppId, application.applicant_type)}
                    >
                      AWARDS
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={5} md={3} lg={2} className={classes.radioGroupLabel}>
                  <label style={{ fontWeight: 600, color: '#000000' }}>Application Status</label>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={10}>
                  <FormControl fullWidth>
                    <RadioGroup
                      row
                      aria-label="approve application"
                      name="approve-application"
                      value={status}
                      onChange={(event) => setAppStatus(event.target.value)}
                      disabled={!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')}
                    >
                      <FormControlLabel
                        value="submitted"
                        className={classes.marginRight40}
                        control={<Radio color="default" disabled={!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')} />}
                        label={
                          <span className={classes.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                            Submitted
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="returned"
                        className={classes.marginRight40}
                        control={<Radio color="default" disabled={!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')} />}
                        label={
                          <span className={classes.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                            Returned
                          </span>
                        }
                      />
                      {showSeniorAppForAdmin() && (
                        <FormControlLabel
                          value="qualified"
                          className={classes.marginRight40}
                          control={<Radio color="default" disabled={!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')} />}
                          label={
                            <span className={classes.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                              Ready for Committee Review
                            </span>
                          }
                        />
                      )}
                      <FormControlLabel
                        value="awarded"
                        className={classes.marginRight40}
                        control={<Radio color="default" disabled={!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')} />}
                        label={
                          <span className={classes.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                            Awarded
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="denied"
                        className={classes.marginRight40}
                        control={<Radio color="default" disabled={!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')} />}
                        label={
                          <span className={classes.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                            Denied
                          </span>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {status !== 'submitted' && status !== 'qualified' && status !== 'qualifiedinitial' && status !== 'qualifiedprogram' && (
                  <React.Fragment>
                    <Grid item xs={12} className={classes.textRowSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="disposition-reason"
                          size="small"
                          fullWidth
                          label={`Enter the reason for status '${titleize(status)}'`}
                          variant="outlined"
                          value={dispositionReason}
                          onChange={(event) => setDispositionReason(event.target.value)}
                          disabled={!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')}
                          InputProps={{ maxLength: 1000, classes: { input: classes.textField } }}
                          className={classes.root}
                        />
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                )}

                {showSeniorAppForAdmin() && (
                  <Grid item xs={12} className={classes.gradingSectionTitle}>
                    Grading the Application
                  </Grid>
                )}

                {showSeniorAppForAdmin() && (
                  <Grid item xs={12} className={classes.textRowSpacer}>
                    <FormControl fullWidth>
                      <TextField
                        id="youth-strengths"
                        size="small"
                        fullWidth
                        label="Enter the strengths of the youth."
                        variant="outlined"
                        value={youthStrengths}
                        onChange={(event) => setYouthStrengths(event.target.value)}
                        disabled={
                          (!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')) ||
                          (status !== 'submitted' && status !== 'qualified' && status !== 'qualifiedinitial' && status !== 'qualifiedprogram')
                        }
                        InputProps={{ maxLength: 500, classes: { input: classes.textField } }}
                        className={classes.root}
                      />
                    </FormControl>
                  </Grid>
                )}

                {showSeniorAppForAdmin() && (
                  <React.Fragment>
                    <Grid item xs={6} sm={3} md={3} lg={2} className={classes.gradeLabelSpacer} align="right">
                      <label>SSM Model Category #</label>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={1} className={classes.textRowSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="ssm-model-category"
                          size="small"
                          hiddenLabel
                          variant="outlined"
                          value={ssmModelCategory}
                          onChange={(event) => setSsmModelCategory(event.target.value)}
                          disabled={
                            (!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')) ||
                            (status !== 'submitted' && status !== 'qualified' && status !== 'qualifiedinitial' && status !== 'qualifiedprogram')
                          }
                          InputProps={{
                            maxLength: 1,
                            classes: { input: classes.textField },
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={1} className={classes.textRowSpacer}></Grid>

                    <Grid item xs={6} sm={3} md={3} lg={2} className={classes.gradeLabelSpacer} align="right"></Grid>
                    <Grid item xs={4} sm={2} md={2} lg={1} className={classes.textRowSpacer}></Grid>

                    <Grid item xs={1} className={classes.textRowSpacer}></Grid>

                    <Grid item xs={6} sm={3} md={3} lg={2} className={classes.gradeLabelSpacer} align="right"></Grid>
                    <Grid item xs={4} sm={2} md={2} lg={1} className={classes.textRowSpacer}></Grid>

                    <Grid item xs={1} className={classes.textRowSpacer}></Grid>

                    <Grid item xs={6} sm={3} md={3} lg={2} className={classes.gradeLabelSpacer} align="right">
                      <label>GPA</label>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={1}>
                      <summary className={classes.gpaSpacer}>{store.senior && store.senior.gpa}</summary>
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} lg={1} className={classes.gradeLabelSpacer} align="left">
                      <Tooltip title="The GPA is maintained on the Personal Information form." placement="top">
                        <InfoIcon />
                      </Tooltip>
                    </Grid>

                    <Grid item xs={4} sm={2} md={2} lg={2} className={classes.gradeLabelSpacer} align="right">
                      <label>Spirituality</label>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={1} className={classes.textRowSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="spirituality-score"
                          size="small"
                          hiddenLabel
                          variant="outlined"
                          value={spiritualityScore}
                          onChange={(event) => setSpiritualityScore(event.target.value)}
                          disabled={
                            (!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')) ||
                            (status !== 'submitted' && status !== 'qualified' && status !== 'qualifiedinitial' && status !== 'qualifiedprogram')
                          }
                          allowNegative={false}
                          InputProps={{
                            maxLength: 1,
                            classes: { input: classes.textField },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={3} className={classes.gradeLabelSpacer} align="right">
                      <label>Pell Amount $</label>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={1} className={classes.textRowSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="pell-amount"
                          size="small"
                          type="number"
                          hiddenLabel
                          variant="outlined"
                          value={pellAmount}
                          onChange={(event) => setPellAmount(event.target.value)}
                          disabled={
                            (!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')) ||
                            (status !== 'submitted' && status !== 'qualified' && status !== 'qualifiedinitial' && status !== 'qualifiedprogram')
                          }
                          InputProps={{
                            maxLength: 5,
                            classes: { input: classes.textField },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                )}
                <Scoring
                  gradingGPA={gradingGPA}
                  setGradingGPA={setGradingGPA}
                  gradingFinancialNeed={gradingFinancialNeed}
                  setGradingFinancialNeed={setGradingFinancialNeed}
                  gradingLengthOfStay={gradingLengthOfStay}
                  setGradingLengthOfStay={setGradingLengthOfStay}
                  gradingEssayVoice={gradingEssayVoice}
                  setGradingEssayVoice={setGradingEssayVoice}
                  gradingEssayInspiration={gradingEssayInspiration}
                  setGradingEssayInspiration={setGradingEssayInspiration}
                  gradingEssayLanguage={gradingEssayLanguage}
                  setGradingEssayLanguage={setGradingEssayLanguage}
                  gradingEssayCompleted={gradingEssayCompleted}
                  setGradingEssayCompleted={setGradingEssayCompleted}
                  gradingCitizenshipLetters={gradingCitizenshipLetters}
                  setGradingCitizenshipLetter={setGradingCitizenshipLetters}
                  gradingCitizenshipActivities={gradingCitizenshipActivities}
                  setGradingCitizenshipActivities={setGradingCitizenshipActivities}
                  gradingCitizenshipRoleModel={gradingCitizenshipRoleModel}
                  setGradingCitizenshipRoleModel={setGradingCitizenshipRoleModel}
                  disabled={!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')}
                  senior={store.senior}
                  applicant_type={store.scholarshipApp.applicant_type}
                />

                {/* <Grid item xs={1}></Grid> */}
                {showSeniorAppForAdmin() && (
                  <>
                    {/* <Grid item xs={12} className={classes.gradingSectionTitle}>
                      Committee Review
                    </Grid> */}

                    {/* <Grid item xs={6} sm={3} md={3} lg={2} className={classes.gradeLabelSpacer} align="right">
                      <label>Application Score</label>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={1} className={classes.textRowSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="application-score"
                          size="small"
                          hiddenLabel
                          variant="outlined"
                          value={applicationScore}
                          onChange={(event) => setApplicationScore(event.target.value)}
                          disabled={
                            (!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')) ||
                            status === 'qualified' ||
                            status === 'qualifiedinitial' ||
                            status === 'qualifiedprogram' ||
                            status === 'submitted' ||
                            status === 'returned'
                          }
                          allowNegative={false}
                          InputProps={{
                            maxLength: 1,
                            classes: { input: classes.textField },
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={1} className={classes.textRowSpacer}></Grid> */}

                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gradingSectionTitle}>
                      Committee Review
                    </Grid>
                    {/* <Grid item xs={6} sm={3} md={3} lg={2} className={classes.gradeLabelSpacer} align="right">
                      <label>Essay Score</label>
                    </Grid> */}
                    <Grid item xs={2} sm={2} md={2} lg={2} className={classes.textRowSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="essay-score"
                          size="small"
                          hiddenLabel
                          variant="outlined"
                          value={essayScore}
                          onChange={(event) => setEssayScore(event.target.value)}
                          label="Essay Score"
                          disabled={
                            (!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST')) ||
                            status === 'qualified' ||
                            status === 'qualifiedinitial' ||
                            status === 'qualifiedprogram' ||
                            status === 'submitted' ||
                            status === 'returned'
                          }
                          allowNegative={false}
                          InputProps={{
                            maxLength: 2,
                            classes: { input: classes.textField },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12} align="left">
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={8} md={5} lg={7} className={classes.buttonSpacer}>
                {(status === 'created' || status === 'returned') && !auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST') && (
                  // allowNewScholarshipApps() && (
                  <Tooltip title="Click to submit your application for consideration." placement="top">
                    <Button
                      className={classes.buttonWidth}
                      color="secondary"
                      variant="contained"
                      onClick={(event) => handleSaveApplication(true, true)}
                      disabled={!store.scholarshipApp}
                      style={{ margin: '0 0 0 10px' }}
                    >
                      Submit
                    </Button>
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1} className={classes.textArea5RowSpacer}></Grid>
              <Grid item xs={12} sm={12} md={5} lg={4} align="right" className={classes.buttonSpacer}></Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <div align="right">
        {showSaveButton() && (
          <Button
            className={classes.floatingButton}
            color="primary"
            variant="contained"
            onClick={(event) => handleSaveApplication(false, true)}
            disabled={!store.scholarshipApp}
            style={{ margin: '0 10px 0 10px' }}
          >
            Save
          </Button>
        )}
      </div>
      <RequiredInfoDialog
        title="Scholarship Application Errors"
        introduction={requiredFieldsIntro}
        content={requiredFields}
        showDialog={showRequiredFieldsDialog}
        handleClose={handleRequiredInfoClose}
      />
      <AwardDialog
        showDialog={showAwardsDialog}
        setShowDialog={setShowAwardsDialog}
        handleClose={closeAwardsDialog}
        application={application}
        senior={store.senior}
        user={auth.user.result.id}
        scholarships={store.scholarships}
        appAwards={store.appAwards}
        setAppAwards={store.setAppAwards}
        showAddAward={showAddAward}
        setShowAddAward={setShowAddAward}
        updateAward={updateAward}
        createAward={createAward}
        updateAwardScholarship={updateAwardScholarship}
        updateAwardStatus={updateAwardStatus}
        updateAwardAmount={updateAwardAmount}
        updateAwardNotes={updateAwardNotes}
        updateAwardedOn={updateAwardedOn}
        awardPendingTotal={awardPendingTotal}
        awardAwardedTotal={awardAwardedTotal}
        awardTotal={awardTotal}
        seniorPlanningScore={seniorPlanningScore}
        updateApplicationStatus={handleSaveApplication}
        showCompleteDialog={showCompleteDialog}
        setShowCompleteDialog={setShowCompleteDialog}
        handleCompleteClose={closeCompleteDialog}
        completeApp={completeScholarshipApplication}
        deleteAward={deleteAward}
        parent="app"
      />
    </React.Fragment>
  );
});

export default ScholarshipApp;
